

import React from 'react';
import './style.scss';
 
const Footer = () =>{
    return (
        <div className="footer">
            <hr/>
            <p>2020-2021 Fortress IQ</p>
        </div>
    )
}

export default Footer;