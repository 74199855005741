import axios from 'axios';
import { store, toObservable } from './store/store';

const axiosConfig = axios.create();

try {
    const state = toObservable(store);
    state.subscribe({ onNext: (state) => 
        axiosConfig.defaults.headers.common['Authorization'] = state?.storeProps?.uuids?.uuid1
    })
} catch(err) {
    console.log('No redux store in localStorage', err)
}

export default axiosConfig;