import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, notification } from 'antd';
import { UserOutlined, EyeOutlined } from '@ant-design/icons';
import ROOT from "../../client";
import Footer from '../Footer';
import { useDispatch, useSelector } from 'react-redux';
import { saveName, saveRouteLocation, saveUuids, updateConfigureFields } from '../../store/actions';
import axiosConfig from '../../axiosConfig';
import crypto from 'crypto-browserify';
import { useLocation, Redirect } from 'react-router-dom';

const LogIn = props => {
  const { sessionFlag } = useSelector(store => store.storeProps);
  const dispatch = useDispatch()
  const location = useLocation();
  const pathName = location?.pathname ? location?.pathname : "";
  const bodyEl = document.getElementById('bodyEl');
  if(bodyEl){
    bodyEl.classList.remove('dashboardBg');
    bodyEl.classList.add('loginBg');
  }
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const passwordRef = useRef(null);

  useEffect(() => {
    dispatch(saveRouteLocation(pathName));
    // eslint-disable-next-line
  }, [])

  const retryCheckToken = () => {
    try{
      const apiPath = `${ROOT}/api/check-token`;
      axiosConfig.get(apiPath).then(resp => {
        if(resp.data.verified === true){
          props.history.push({
            pathname: '/indices',
            source: 'LOGIN',
          });
        }else{
          retryCheckToken();
        }
      });
    } catch(error) {
      console.log(error);
    }
  }
  
  const onFinish = values => {
    try{
      if(values.username && values.password){
        setLoading(true);
        const apiPath = `${ROOT}/api/login`;
        dispatch(saveName(values.username))
        let infoObj = {
          username: values.username,
          password: values.password
        }
        infoObj = JSON.stringify(infoObj);
        const ivBuf = Buffer.from("phantom@security");
        const securityBuf = Buffer.from("phantom@securityphantom@security")
        let algo = 'aes-256-cbc';
        let cipher = crypto.createCipheriv(algo, securityBuf, ivBuf);
        let cred = cipher.update(infoObj, "utf-8", "hex");
        cred += cipher.final("hex");
        axiosConfig.post(apiPath, {cred}).then(resp => {
          setLoading(false);
          if(resp?.data?.statusCode === 200){
            dispatch(saveUuids({
              uuid1: resp?.data?.uid1,
              uuid2: resp?.data?.uid2
            }));
            retryCheckToken();
            dispatch(updateConfigureFields([{label: "title"}]));
          }
        }).catch(error => {
          setLoading(false);
          if(error?.response?.data?.message){
            notification.error({
              message: error.response.data.message
            });
          }
          return;
        });
      }
    } catch(error) {
      console.log(error);
    }
  }

  const handleEnter = (e) => {
    try{
      passwordRef.current.focus();
      e.preventDefault();
    } catch(error) {
      console.log(error);
    }
  }

  const handleMouseDown = (e) => {
    try{
      setPasswordVisible(true)
      passwordRef.current.focus();
      e.preventDefault();
    } catch(error) {
      console.log(error);
    }
  }

  const handleMouseUp = (e) => {
    try{
      setPasswordVisible(false)
      passwordRef.current.focus();
      e.preventDefault();
    } catch(error) {
      console.log(error);
    }
  }

  return(
    <>
    {sessionFlag ? 
      (<Redirect to="/dashboard" />) : (
        <>
        <div className="flex-container-column">
          <div className="logo-login">
            <img src="./fortress-logo-login.png" alt="FIQ" />
          </div>
          <div className="flex-container-row">
            <div className="heading">
                <p className="head">Unlock the limitless potential of the global workforce</p>
                <p className="sub-head">FortressIQ uses AI and Computer Vision to capture and discover all relevant process data in just weeks.</p>
            </div>
            <div className="loginWrapper">
              <div className="loginHead">
                <h1><strong>Please login to Fortress IQ</strong></h1>
              </div>
              <Form  className="form-login" onFinish={onFinish} requiredMark={false} layout="vertical">
                <Form.Item
                  name="username"
                  rules={[{
                    validator(_, value) {
                      if(value){
                        const specialCharRegex = new RegExp(/^[^\\//#?%:$ ]+$/);
                        if(!specialCharRegex.test(value)) {
                          return Promise.reject(new Error(`Username must contain only letters, numbers, special characters as per the elastic constraints. Spaces are not allowed.`))
                        } else {
                          return Promise.resolve()
                        }
                      } else {
                        return Promise.reject(new Error(`Please enter your username.`))
                      }
                    }
                  }]}
                  label="User Name"
                  className="login-label"
                >
                  <Input className="username" 
                    suffix={<UserOutlined className="site-form-item-icon" style={{ fontSize: '18px', color: '#5e5e5e' }} />} 
                    placeholder="Enter Name"
                    onPressEnter={handleEnter}
                    autoComplete="username"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your password',
                    },
                  ]}
                  label="Password"
                  className="login-label"
                >
                  <Input
                    ref={passwordRef} 
                    suffix={<EyeOutlined onMouseDown={(e)=>handleMouseDown(e)} onMouseUp={(e)=>handleMouseUp(e)}  className="eyeIcon site-form-item-icon" style={{ cursor: 'pointer', fontSize: '18px', color: '#5e5e5e' }}  />}
                    type={passwordVisible ? "text" : "password"}
                    className="password" 
                    placeholder="Enter Password"
                    autoComplete="current-password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button className="btnLogin" type="primary" htmlType="submit" block  size="large" loading={loading}>
                    Login
                  </Button>
                  <Button block className="backBtn" type="link" href="/"><span className="backIcon">&#8592;</span> Back to Configuration Settings</Button>
                </Form.Item>
              </Form>
            </div>
          </div>
          <Footer/>
        </div>
      </> 
      )
    }
    </>
  )
}
export default LogIn;