import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducers";

const applyMiddlewareModule = process.env.NODE_ENV !== 'development' ? applyMiddleware(thunk) : composeWithDevTools({ trace: true, traceLimit: 50})(applyMiddleware(thunk));

export const store = createStore(
    persistReducer(
        {
            key: 'fortress',
            storage
        },
        rootReducer
    ), 
    applyMiddlewareModule);

export const persistor = persistStore(store);

// observer to get store outside react containers
export function toObservable(store) {
    return {
      subscribe({ onNext }) {
        let dispose = store.subscribe(() => onNext(store.getState()));
        onNext(store.getState());
        return { dispose };
      },
    };
  }