import React, { useEffect, useState } from 'react'
import { Button } from "antd";
import { WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import "./pdd.scss";

const PddPopover = (props) => {
    const { searchData, pddDetail} = useSelector(store => store.storeProps);
    const [valid, setValid] = useState(true);
    const {setPddVisible} = props;
    // eslint-disable-next-line
    const [dataWarning, setDataWarning] = useState(false);
    useEffect(() => {
        const pddStatus = pddDetail?.status ? pddDetail.status : false;
        setDataWarning(pddStatus);
        setValid(pddStatus);
    }, [pddDetail]);

    const confirm = (tag) => {
        try{
            if(tag && tag === 'yes'){
                const pddLink = document.getElementById("pddLink");
                if(pddLink){
                    pddLink.click();
                }
            }
            setPddVisible(false);
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <div className="pddWrapper">
            <h4>Generate PDD</h4>
            {!valid && (
                <div className="errorMessage">Please add required observer filter to generate PDD.</div>
            )}
            {dataWarning && (
                <div className="dataWarningWrapper">
                <div className="warningMessage"><WarningOutlined /><p>{searchData?.length ? searchData.length : ''} records found. It will take time to generate PDD. Are you sure to conitnue with it.</p></div>
                <div className="confirmBtns">
                    <Button id="noBtn" onClick={() => confirm('no')}>No</Button>
                    <Button id="yesBtn" type="primary" onClick={() => confirm('yes')}>Yes</Button>
                </div>
            </div>
            )}
            <a href="/pdd" id="pddLink" target="_blank" style={{display: 'none'}}>PDD</a>
        </div>
    )
}
export default PddPopover;