import React from 'react';
import Annotation from 'react-image-annotation';
import { ReactCompareSlider } from 'react-compare-slider';
import { useSelector } from 'react-redux';

const RenderImage = ({
    ind,
    imageData,
    annotations,
    annotation,
    renderEditor,
    renderHighlight,
    onAnnotationChange,
    onAnnotationSubmit,
}) => {
    const { view } = useSelector(store => store.storeProps);
    const handleClick = () => {
        document?.querySelector?.('.carousel') && document.querySelector('.carousel').focus();
    }

    const fallbackImage = (e) => {
        e.target.src = './no-image.png';
    }
    
    if (view === 'masked' && imageData.maskedUrl !== '') {
        return (
            <ReactCompareSlider
                onClick={handleClick}
                position={95}
                onlyHandleDraggable={true}
                handle={
                    <div className="updatedArrows">
                        <button>
                            <div className="arrowBtn">
                                <div className=" arrow left">

                                    <svg
                                        width="8px"
                                        height="10px"
                                        viewBox="0 0 50 80">
                                        <polyline
                                            fill="none"
                                            stroke="#FFFFFF"
                                            strokeWidth="16"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            points="45.63,75.8 0.375,38.087 45.63,0.375 " />
                                    </svg>
                                </div>
                                <div className="arrow right">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8px"
                                        height="10px"
                                        viewBox="0 0 50 80" >
                                        <polyline
                                            fill="none"
                                            stroke="#FFFFFF"
                                            strokeWidth="16"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            points="0.375,0.375 45.63,38.087 0.375,75.8 " />
                                    </svg>
                                </div>
                            </div>
                        </button>
                    </div>}
                itemOne={
                    <img
                        src={imageData.url}
                        onError={(e) => fallbackImage(e)}
                        alt=""
                    />}
                itemTwo={
                    <img
                        src={imageData.maskedUrl}
                        onError={(e) => fallbackImage(e)}
                        alt=""
                    />}
            />
        )
    } else {
        return (
            <Annotation
                key={`annotation_${ind}`}
                src={imageData.url}
                annotations={annotations[ind] ? annotations[ind] : []}
                value={annotation}
                renderEditor={renderEditor}
                renderHighlight={renderHighlight}
                onChange={onAnnotationChange}
                onSubmit={() => onAnnotationSubmit(ind, imageData.url)}
            />
        )
    }
}

export default RenderImage