import React, { useState } from 'react'
import Bucket from '../Bucket/bucket';
import Settings from '../Settings';
import { Col, Dropdown, Layout, Row, Tooltip } from 'antd';
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
const { Header } = Layout;

const TopHeader = ({ bucket, setIsLoading }) => {
  const { uuids, username } = useSelector(store => store.storeProps);
    
    const [visibleMenu, setVisibleMenu] = useState(false);
    const token = uuids?.uuid1;

    const handleVisibleChange = (flag) => {
        setVisibleMenu(flag);
    }

    const menu = (
        <Settings  
        setIsLoading={setIsLoading}
        setVisibleMenu={setVisibleMenu}
        />
    );

    if(token) {
        return (
            <Header className="header">
            <Row align="middle">
              <Col span={3}>
                <img src="./fortress-logo-login.png" className="logo-dashboard" alt="FIQ" />
              </Col>
              <Col span={21}>
                <div className="rightBlock">
                  {
                      bucket
                      && (
                        <div className="bucketWrapper">
                            <Bucket setIsLoading={setIsLoading} />
                        </div>
                      )
                  }
                  <div className="logout-wrapper">
                    <UserOutlined className="user-icon" style={{ fontSize: '18px' }} />
                    <Dropdown visible={visibleMenu} onVisibleChange={handleVisibleChange} overlay={menu} trigger={["click"]}>
                      <a href="true" className="ant-dropdown-link logout-link" onClick={(e) => e.preventDefault()}>
                      <Tooltip placement="bottomRight" title={username}>
                        <span className='menu-username' style={{marginRight: "3px"}}>{username}</span>
                      </Tooltip>
                      <CaretDownOutlined  />
                      </a>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>
          </Header>
        )
    }

    return null;
  
}

export default TopHeader;