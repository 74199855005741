import { ADD_INDEX_VALUE, SAVE_SEARCH_DATA, SAVE_IMG_URL, SAVE_FILTERS, SAVE_DATE, SAVE_ALL_DATA_IDS, SAVE_SCREEN_SIGNATURE, SAVE_IMAGE_LOAD_COUNT, SAVE_MAPPING_KEY, UPDATE_CONFIGURE_FIELDS, SAVE_AREA_QUERY, SAVE_CONFIGURE_FIELD_STATUS, UPDATE_ENVARS, SAVE_GCP_FILENAME, SAVE_PDD_DETAIL, SAVE_BUCKETS, SAVE_SELECTED_BUCKET, SAVE_ENVARS, SAVE_SLOTS, SAVE_IMAGE_KEYS, SAVE_FILE_CONFIGURATIONS, SAVE_IMAGE_DETAIL, SAVE_MASKED_BUCKET, SET_VIEW, SAVE_MAPPING_PROPS, SAVE_QUERY_STACK, SAVE_GRAPH_VISIBILITY, SAVE_MINIO_CLIENT, SET_ENABLE_SEARCH, SAVE_MASKED_FLAG, SAVE_UUIDS, SAVE_NAME, SAVE_ROUTE_LOCATION, SAVE_SESSION_FLAG } from '../types';

const initialIndexValue = localStorage.getItem('indexVal');
const initialMappingKey = localStorage.getItem('defaultImageKey');

export const initialState = {
    indexValue: initialIndexValue ? initialIndexValue : '',
    allIndexes: '',
    indexAlias: '',
    aliasIndexesArr: [],
    aliasFlag: false,
    searchData: [],
    imagesUrls: [],
    filters: '',
    observedAtDetail: '',
    allDataIds: [],
    mappingImageKey: initialMappingKey ? initialMappingKey : '',
    screenSignature: '',
    imageLoadCount: 25,
    configuredFields: [{label: 'title'}],
    areaQuery: '',
    isConfigureFieldEnable: true,
    updateEnvars: false,
    gcpFileName: '',
    pddDetail: '',
    buckets: '',
    selectedBucket: '',
    envars: '',
    carouselSlots: ["1"],
    imageKeys: [],
    fileConfigurations: "",
    imageDetail: [],
    maskedBucket: "",
    view: 'annotation',
    mappingProps: "",
    queryStack: [],
    graphVisibility: false,
    minioClient: "",
    enableSearch: false,
    maskedFlag: false,
    uuids:{},
    username: "",
    routeLocation: "",
    sessionFlag: false
}

// eslint-disable-next-line
export default (state = initialState, action) => {
    const obj = {
        ...state,
        ...action.payload
    };
    switch (action.type){
        case ADD_INDEX_VALUE: {
            return obj
        }
        case SAVE_SEARCH_DATA: {
            return obj
        }
        case SAVE_IMG_URL: {
            return obj
        }
        case SAVE_FILTERS: {
            return obj
        }
        case SAVE_DATE: {
            return obj
        }
        case SAVE_ALL_DATA_IDS: {
            return obj
        }
        case SAVE_MAPPING_KEY: {
            return obj
        }
        case SAVE_IMAGE_LOAD_COUNT: {
            return obj
        }
        case SAVE_SCREEN_SIGNATURE: {
            return obj
        }
        case UPDATE_CONFIGURE_FIELDS: {
            return obj
        }
        case SAVE_AREA_QUERY: {
            return obj
        }
        case SAVE_CONFIGURE_FIELD_STATUS: {
            return obj
        }
        case UPDATE_ENVARS: {
            return obj
        }
        case SAVE_GCP_FILENAME: {
            return obj
        }
        case SAVE_PDD_DETAIL: {
            return obj
        }
        case SAVE_BUCKETS: {
            return obj
        }
        case SAVE_SELECTED_BUCKET: {
            return obj
        }
        case SAVE_ENVARS: {
            return obj
        }
        case SAVE_SLOTS: {
            return obj
        }
        case SAVE_IMAGE_KEYS: {
            return obj
        }
        case SAVE_FILE_CONFIGURATIONS: {
            return obj
        }
        case SAVE_IMAGE_DETAIL: {
            return obj
        }
        case SAVE_MASKED_BUCKET: {
            return obj
        }
        case SET_VIEW: {
            return {
                ...state,
                view: action.payload
            }
        }
        case SAVE_MAPPING_PROPS: {
            return obj
        }
        case SAVE_QUERY_STACK: {
            return obj
        }
        case SAVE_GRAPH_VISIBILITY: {
            return obj
        }
        case SAVE_MINIO_CLIENT: {
            return obj
        }
        case SET_ENABLE_SEARCH: {
            return obj
        }
        case SAVE_MASKED_FLAG: {
            return obj
        }
        case SAVE_UUIDS: {
            return obj
        }
        case SAVE_NAME: {
            return obj
        }
        case SAVE_ROUTE_LOCATION: {
            return obj
        }
        case SAVE_SESSION_FLAG: {
            return obj
        }
        default:
        return state;
    }
}