import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Button, Select, Row, Col, notification, Tooltip } from 'antd';
import {InfoCircleFilled } from '@ant-design/icons';
import './indices.scss';
import ROOT from "../../client";
import { saveIndexValue, saveMappingKey, saveDate, savePddDetail, saveBuckets, saveSelectedBucket, saveImageDetail, saveMaskedBucket, saveMappingProps, saveQueryStack, saveGraphVisibility, saveSearchData, saveAllDataIds, saveImageKeys, saveMinioClient, setEnableSearch, saveMaskedFlag, saveEnvars, saveRouteLocation, saveSessionFlag } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ImageKeys from '../ImageKeys';
import TopHeader from '../TopHeader';
import axiosConfig from '../../axiosConfig';
import axios from 'axios';
const { Option } = Select;

const ShowIndices = (props) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const pathName = location?.pathname ? location?.pathname : "";
    const bodyEl = document.getElementById('bodyEl');
    if(bodyEl){
        bodyEl.classList.add('dashboardBg');
        bodyEl.classList.remove('loginBg');
    }
    const source = props?.history?.location?.source;
    const {indexValue, fileConfigurations, envars, uuids, username} = useSelector(store => store.storeProps);
    const token = uuids?.uuid1;
    const [indexOptions, setIndexOptions] = useState([]);
    const [indexListValue, setIndexListValue] = useState(localStorage.getItem('selectedIndexPattern'));
    const defaultImgKey = localStorage.getItem('defaultImageKey') ? localStorage.getItem('defaultImageKey') : '';
    const [imageKey, setImageKey] = useState(defaultImgKey);
    const [isOpendistro, setIsOpendistro] = useState(false);
    const [indexFlag, setIndexFlag] = useState(false);

    const goToSearchPage = () => {
        props.history.push({
            pathname: '/dashboard',
            source: 'Indices',
        });
    }
    // Get list of indices on page load
    useEffect(() => {
        dispatch(saveRouteLocation(pathName));
        if(token){
            const cancelTokenSource = axios.CancelToken.source();
            try {
                dispatch(saveSessionFlag(true))
                dispatch(saveMappingKey(defaultImgKey));
                dispatch(saveDate({
                    dateRange: '',
                    startDate: '',
                    endDate: ''
                }));
                dispatch(saveBuckets(''));
                dispatch(saveSelectedBucket(''));
                dispatch(saveMaskedBucket(''));
                dispatch(saveQueryStack([]));
                dispatch(saveGraphVisibility(false));
                dispatch(saveSearchData([]));
                dispatch(saveAllDataIds([]));
                dispatch(saveImageKeys([]));
                dispatch(saveMinioClient(""));
                dispatch(setEnableSearch(false));
                dispatch(saveMaskedFlag(false));
                axiosConfig.get(`${ROOT}/api/get-env`)
                .then(resp => {
                    if(resp && resp.data && resp.data.statusCode === 200 && resp.data.envData){
                        let envarData = resp.data.envData;
                        const essl = envarData.elasticSsl && envarData.elasticSsl === 'true' ? true : false;
                        const ussl = envarData.useSsl ? envarData.useSsl === 'true' ? true : false : true;
                        const mPort = envarData.minioPort ? JSON.stringify(envarData.minioPort) : '';
                        envarData = {...envarData, elasticSsl: essl, useSsl: ussl, minioPort: mPort, elasticType: envarData.elasticType && envarData.elasticType !== 'OPENDISTRO' ? envarData.elasticType.toLowerCase() : envarData.elasticType};
                        dispatch(saveEnvars(envarData));
                    }
                })
                .catch(error => {
                    console.log(error);
                    if(error?.response?.data?.message){
                        notification.error({
                            message: error.response.data.message,
                            duration: 5
                        }) 
                    }
                })
                const apiPath = `${ROOT}/api/get-all-indices`;
                axiosConfig.get(apiPath, {
                    cancelToken: cancelTokenSource.token,
                  }).then(resp => {
                    if(resp && resp.data && resp.data.data){
                        const observerKeys = resp.data.observerKeys ? resp.data.observerKeys : '';
                        dispatch(savePddDetail({
                            status: false, 
                            dataLength: 0,
                            keys: observerKeys
                        }));
                        const indexOpts = [];
                        let aliasArr = [];
                        const opendistroFlag = resp.data.data.opendistro;
                        setIsOpendistro(opendistroFlag);
                        const indicesArr = resp.data.data.indices ? resp.data.data.indices : [];
                        indicesArr.forEach((val, i) => {
                            if(val.alias){
                                aliasArr.push(val.alias);
                                if(opendistroFlag){
                                    indexOpts.push({
                                        label: val.index,
                                        value: val.index,
                                        alias: val.alias ? val.alias : '',
                                        index: val.index,
                                        aliasFlag: false
                                    });
                                }
                                indexOpts.push({
                                    label: val.alias,
                                    value: val.alias,
                                    alias: val.alias,
                                    index: val.index,
                                    aliasFlag: true
                                });
                            } else {
                                indexOpts.push({
                                    label: val.index,
                                    value: val.index,
                                    alias: '',
                                    index: val.index,
                                    aliasFlag: false
                                });
                            }
                        });
                        let newIndexArr = [];
                        if(aliasArr.length){
                            aliasArr = [...new Set(aliasArr)];
                            aliasArr.forEach(als => {
                                let aliasIndexes = '';
                                let aliasIndexVal = '';
                                let aliasIndexArr = [];
                                indexOpts.forEach((val, i) => {
                                    if(als === val.value && val.aliasFlag){
                                        aliasIndexes = aliasIndexes ? `${aliasIndexes}, ${val.index}` : val.index;
                                        aliasIndexVal = val.index;
                                        aliasIndexArr.push(val.index);
                                    } else if(!val.aliasFlag){
                                        let matchVal = "";
                                        if(newIndexArr.length){
                                            matchVal = newIndexArr.find(item => item.value === val.value);
                                        }
                                        if(!matchVal){
                                            newIndexArr.push(val);
                                        }
                                    }
                                })
                                newIndexArr.push({
                                    label: als,
                                    value: als,
                                    alias: als,
                                    index: aliasIndexVal,
                                    aliasIndexesArr: aliasIndexArr,
                                    allIndexes: aliasIndexes,
                                    aliasFlag: true
                                })
                            })
                        } else {
                            newIndexArr = indexOpts;
                        }
                        setIndexOptions(newIndexArr);
                        if(fileConfigurations?.storageMechanism !== "gcp" && envars?.bucketConfigAccess){
                            const bucketConfig = {...fileConfigurations};
                            if(bucketConfig.bucketName){
                                if(bucketConfig.bucketFolderName){
                                    bucketConfig.bucketName = `${bucketConfig.bucketName}/${bucketConfig.bucketFolderName}`;
                                    delete bucketConfig.bucketFolderName;
                                }
                                const payload = {
                                    bucketName: bucketConfig.bucketName,
                                    minioPort: bucketConfig.minioPort,
                                    minioEndPoint: bucketConfig.minioEndPoint,
                                    minioAccessKey: bucketConfig.minioAccessKey,
                                    minioSecretKey: bucketConfig.minioSecretKey,
                                    useSsl: bucketConfig.useSsl,
                                }
                                axiosConfig.get(`${ROOT}/api/bucket-names`).then(res => {
                                    if(res?.data?.data.length){
                                        const data = res.data.data;
                                        const isBucketExist = data.find(row => row.bucketName === bucketConfig.bucketName);
                                        if(!isBucketExist){
                                            configBucket(payload);
                                        }
                                    } else {
                                        configBucket(payload);
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                })
                            }
                        }
                    }
                    if(indexValue){
                        getImageKeys(indexValue);
                    }
                });
            } catch (error) {
                console.log(error);
            }
            return () => {
                cancelTokenSource.cancel();
            }
        }
        // eslint-disable-next-line
    }, []);

    const configBucket = (payload) => {
        try{
            if(payload){
                axiosConfig.post(`${ROOT}/api/bucket-names`, payload).then()
                .catch(error => {
                    if(error?.response?.data?.message && error?.response?.data?.message !== "This bucket already exists."){
                        notification.error({
                            message: error.response.data.message,
                        })
                    }
                })
            }
        } catch(error) {
            console.log(error);
        }
    }
    
    const [disable, setDisable] = useState(true);
    useEffect(() => {
        try{
            const disabled = indexValue && imageKey;
            setDisable(disabled ? false : true);
        } catch(error) {
            console.log(error);
        }
    }, [indexValue, imageKey])
    
    // on index pattern selection from dropdown
    const onSelectIndex = (event) => {
        try{
            setIndexFlag(false);
            const selectedIndex = indexOptions.filter(item => item.label === event);
            if(selectedIndex.length){
                const selectedIndexVal = selectedIndex[0].index;
                const selectedAlias = selectedIndex[0].alias;
                dispatch(saveIndexValue({
                    index: selectedIndexVal ? selectedIndexVal : '', 
                    alias: selectedAlias ? selectedAlias : '', 
                    aliasFlag: selectedIndex[0]?.aliasFlag ? selectedIndex[0].aliasFlag : false, 
                    allIndexes: selectedIndex[0]?.allIndexes ? selectedIndex[0].allIndexes : '', 
                    aliasIndexesArr: selectedIndex[0]?.aliasIndexesArr ? selectedIndex[0].aliasIndexesArr : []
                }));
                localStorage.setItem('indexVal', selectedIndexVal);
                localStorage.setItem('aliasVal', selectedAlias);
                localStorage.setItem('selectedIndexPattern', event);
                setIndexListValue(event);
                getImageKeys(selectedIndexVal);
            }
        } catch(error) {
            console.log(error);
        }
    }
  
    // on image key selection from dropdown
    const onSelectImageKey = event => {
        try{
            localStorage.setItem('defaultImageKey', event);
            setImageKey(event);
            dispatch(saveMappingKey(event));
        } catch(error) {
            console.log(error);
        }
    }

    const getImageKeys = (index) =>{
        try {
            const url = `${ROOT}/api/get-mapping`;
            const requestOptions = {
              index
            };
            axiosConfig.post(url, requestOptions)
            .then(json => {
              if (json && json.data && json.data.data) {
                const properties = json.data.data[Object.keys(json.data.data)[0]]['mappings']['properties'];
                if(properties){
                  let mapping_keys = Object.keys(properties);
                  dispatch(saveMappingProps(properties));
                  const imgKeyOpt = mapping_keys.map(val => {
                    return {
                      label: val,
                      value: val,
                      type: properties[val]?.type,
                      requireLikeOperator: properties[val]?.isLikeOperator ? true : false
                    }
                  });
                  dispatch(saveImageDetail(imgKeyOpt))
                }
              }
            })
            .catch(error => {
                if(error?.response?.data?.message){
                    setIndexFlag(error.response.data.message.indexOf("index_not_found_exception") !== -1 ? true : false);
                    notification.error({
                        message: error.response.data.message.indexOf("index_not_found_exception") !== -1 ? "Selected index not found. Please change the index." : error.response.data.message,
                        duration: 7
                    })
                }
            })
          } catch (error) {
                console.log(error);
          }
    }

    return (
        <>
            {!token ?
                (<Redirect to="/login" />) : (
                <>
                    <TopHeader bucket={false} setIsLoading={() =>{}} />
                    <div className="indexContainer">
                        <div className="indexBlock">
                            { source === 'LOGIN' ? (
                                <>
                                <Row style={{ width: '100%' }} justify="center" align="middle"  >
                                    <Col className="user-header">
                                    Welcome 
                                    <Tooltip placement="top" title={username}>
                                        <span className='menu-username'>{username}</span>
                                    </Tooltip>
                                    </Col>
                                </Row>
                                <Row style={{ width: '100%' }} justify="center" align="middle"  >
                                    <Col className="user-sub-header">
                                    Select index pattern and image key field to continue
                                    </Col>
                                </Row>
                                </>
                            ):(
                                <Row style={{ width: '100%' }} justify="center" align="middle"  >
                                    <Col  span={13}  className="user-edit">
                                        Change the Index Pattern and Image Key Field
                                    </Col>
                                </Row>
                            )}
                            <Row style={{ width: '100%' }} gutter={30}  >
                                <Col span={8} className="label-wrapper">
                                    <label className="index-label">Index Pattern </label>
                                </Col>
                                <Col span={12}>
                                    <Select
                                        style={{ width: '100%'}}
                                        placeholder="Select a value"
                                        onChange={onSelectIndex}
                                        defaultValue={indexListValue}
                                        showSearch
                                        className={`index-select ${indexFlag ? "errorField" : ""}`}
                                    >
                                        {indexOptions.length > 0 && (
                                            indexOptions.map((element, index) => {
                                                return (
                                                    <Option key={index} value={element.value}>{`${element.aliasFlag ? `${element.label} ${element.allIndexes ? `(${element.allIndexes})` : `${index}`}` : `${element.label} ${isOpendistro ? element.alias ? `(${element.alias})` : '' : ''}`}`}</Option>
                                                )
                                            })
                                        )}
                                    </Select>
                                </Col>
                            </Row>
                            <Row style={{ width: '100%' }} gutter={30} >
                                <Col span={8}  className="label-wrapper">
                                    <label className="index-label">Image Key Field </label>
                                </Col>
                                <Col span={12}>
                                    <ImageKeys imageKey={imageKey} onSelectImageKey={onSelectImageKey}/>
                                </Col>
                            </Row>
                            <Row style={{ width: '100%' }} gutter={30} >
                                <Col span={12} offset = {8}>
                                    <Button  style={{ width: '100%'}} className="btnGo" disabled={disable} type="primary" size="large" onClick={goToSearchPage}>{source === 'LOGIN' ? 'Continue' : 'Change'}</Button>
                                </Col>
                            </Row>
                            { source === 'LOGIN' ? (
                                <Row style={{ width: '100%' }} >
                                    <Col className="indices-footer">
                                        <InfoCircleFilled  style={{ fontSize: '17px', color:'red' }}/>
                                        <span>Later you can change <strong>Index Pattern & Image Key</strong> under <strong>User Profile </strong>Dropdown</span>
                                    </Col>
                                </Row>
                            ): (
                                <Row style={{ width: '100%' }} >
                                    <Col className="indices-footer">
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </div>
                </>
                )
            }
        </>
    )
}

export default ShowIndices;