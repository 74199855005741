import React, { useEffect, useState } from 'react';
import {
  EuiSuperDatePicker
} from '@elastic/eui';
import { notification } from "antd";
import "./dateTimePicker.scss";
import dateMath from '@elastic/datemath';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep,sortBy } from 'lodash';
import { saveDate, saveAllDataIds, saveSearchData, saveImageLoadCount, saveAreaQuery, configureFieldStatus, savePddDetail, saveImageKeys, setView, setEnableSearch, saveMinioClient, saveMaskedFlag } from "../../store/actions";
import { getSearcData } from "../../services";
import ROOT from '../../client';
import { getMinioClient } from '../../services';
import axiosConfig from '../../axiosConfig';

const DateTimePicker = (props) => {
  const dispatch = useDispatch();
  const {indexValue, indexAlias, aliasFlag, mappingImageKey, screenSignature, configuredFields, observedAtDetail, pddDetail, selectedBucket, imageDetail, maskedBucket, view, enableSearch, buckets, envars, maskedFlag} = useSelector(store => store.storeProps);
  const { setFilterValue, setLowerRange, setHigherRange, setGraphdata, setQueryData, setSelectedOption, setAreaQuery, setAnnotations, graphEvent, setGraphEvent, setIsLoading, isLoading, setEnableExport, setQueryDate, queryString, setNoRecord } = props;
  const [start, setStart] = useState('now-15d');
  const [end, setEnd] = useState('now');
  const timezone = moment.tz.guess();
  useEffect(() => {
    try{
      if(graphEvent){
        onTimeChange(graphEvent);
      }
    } catch(error) {
        console.log(error);
    }
    // eslint-disable-next-line
  }, [graphEvent]);

  useEffect(() => {
    try{
      if(enableSearch && observedAtDetail.startDate){
        let event = {};
        event.start = observedAtDetail && observedAtDetail.startDate ? observedAtDetail.startDate : start;
        event.end = observedAtDetail && observedAtDetail.endDate ? observedAtDetail.endDate : end;
        event.chart = false;
        onTimeChange(event);
        dispatch(setEnableSearch(false));
      }
    } catch(error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [queryString, screenSignature, selectedBucket, maskedBucket])

  const createMinioClient = () => {
    return new Promise((resolve, reject) => {
      try{
        if(buckets.length && selectedBucket){
          let mc1 = "";
          const bucket1Info = buckets.find(item => item.bucketName === selectedBucket.bucketName);
          if(bucket1Info){
            if(envars?.bucketConfigAccess){
              axiosConfig.get(`${ROOT}/api/bucket-data?metaInfo=${bucket1Info.metaInfo}&secrets=true`)
              .then(res => {
                if(res?.data?.secrets){
                  const data = JSON.parse(Buffer.from(res.data.secrets, 'base64').toString('ascii'));
                  mc1 = getMinioClient({
                    endPoint: data.endpoint ? data.endpoint : "storage-fiq-53e0863f-82db-4403.fiq-dev.com",
                    port: data.PORT ? Number(data.PORT) : 443,
                    useSSL: data.USE_SSL ? data.USE_SSL : true,
                    accessKey: data.access_key_id ? data.access_key_id : "fortressiq",
                    secretKey: data.secret_access_key ? data.secret_access_key : "mn2smDXjJW9cWbrpG5"
                  })
                  if(maskedBucket){
                    const bucket2Info = buckets.find(item => item.bucketName === maskedBucket.bucketName);
                    if(bucket2Info){
                      axiosConfig.get(`${ROOT}/api/bucket-data?metaInfo=${bucket2Info.metaInfo}&secrets=true`)
                      .then(res2 => {
                        if(res2?.data?.secrets){
                          const dataB2 = JSON.parse(Buffer.from(res2.data.secrets, 'base64').toString('ascii'));
                          const mc2 = getMinioClient({
                            endPoint: dataB2.endpoint ? dataB2.endpoint : "storage-fiq-53e0863f-82db-4403.fiq-dev.com",
                            port: dataB2.PORT ? Number(dataB2.PORT) : 443,
                            useSSL: dataB2.USE_SSL ? dataB2.USE_SSL : true,
                            accessKey: dataB2.access_key_id ? dataB2.access_key_id : "fortressiq",
                            secretKey: dataB2.secret_access_key ? dataB2.secret_access_key : "mn2smDXjJW9cWbrpG5"
                          })
                          dispatch(saveMinioClient({firstBucket: mc1, secondBucket: mc2}));
                          resolve(true);
                        }
                      })
                      .catch(error => {
                        reject(false);
                        setIsLoading(false);
                        console.log(error)
                      })
                    }
                  } else {
                    dispatch(saveMinioClient({firstBucket: mc1}));
                    resolve(true);
                  }
                }
              })
              .catch(error => {
                setIsLoading(false);
                reject(false);
                console.log(error);
              })
            } else {
              const mc = getMinioClient({
                endPoint: envars?.minioEndPoint ? envars.minioEndPoint : "storage-fiq-53e0863f-82db-4403.fiq-dev.com",
                port: envars?.minioPort ? Number(envars.minioPort) : 443,
                useSSL: envars?.useSsl ? envars.useSsl : true,
                accessKey: envars?.minioAccessKey ? envars.minioAccessKey : "fortressiq",
                secretKey: envars?.minioSecretKey ? envars.minioSecretKey : "mn2smDXjJW9cWbrpG5"
              })
              dispatch(saveMinioClient({firstBucket: mc, secondBucket: mc}));
              resolve(true);
            }
          }
        }
      } catch(error) {
        setIsLoading(false);
        console.log(error);
      }
    })
  }
  
  // on query search
  const onTimeChange = (e) => {
    try{
      if(view === 'masked' && maskedBucket === '') {
        dispatch(setView('annotation'));
      }
      if(view === 'bounding-box') {
          if(maskedBucket === '') {
            dispatch(setView('annotation'));
          } else {
            dispatch(setView('masked'));
          }
      }
      if(maskedFlag && maskedBucket){
        dispatch(setView('masked'));
        dispatch(saveMaskedFlag(false));
      }
      if(!isLoading){
        setIsLoading(true);
      }
      let startDateVal = '';
      let endDateVal = '';
      if(e.chart) {
        startDateVal = e.start;
        endDateVal = e.end;
      } else {
        startDateVal = moment(dateMath.parse(e.start)).format('YYYY-MM-DDTHH:mm:ss');
        endDateVal = moment(dateMath.parse(e.end === "now/d" || e.end === "now/w" || e.end === "now/M" || e.end === "now/y" ? "now" : e.end)).format(e.end === "now-1d/d" ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm:ss');
        if(e.end === "now-1d/d"){
          endDateVal = `${endDateVal}T23:59:59`;
        }
      }
      if(startDateVal && endDateVal){
        startDateVal = moment.tz(startDateVal, timezone).format();
        endDateVal = moment.tz(endDateVal, timezone).format();
      }
      let elasticQuery = queryString ? queryString : '';
      let observedAt = startDateVal && endDateVal ? {
          key: 'observed_at',
          value: `${startDateVal} AND ${endDateVal}`,
          operator: 'BETWEEN',
          operand: 'AND'
      } : '';
      if(envars?.storageMechanism === "minio"){
        if(selectedBucket){
          createMinioClient().then(res => {
            if(res){
              getData(e, elasticQuery, observedAt)
            } else {
              notification.error({
                message: "Something went wrong. Please check try later."
              })
            }
          });
        }
      } else {
        getData(e, elasticQuery, observedAt)
      }
      if(observedAtDetail){
        if(observedAtDetail.startDate !== startDateVal || observedAtDetail.endDate !== endDateVal){
          saveObservedAt(observedAt, startDateVal, endDateVal);
        }
      } else {
        saveObservedAt(observedAt, startDateVal, endDateVal);
      }
    } catch(error) {
      console.log(error);
    }
  };

  const saveObservedAt = (observedAt, startDate, endDate) => {
    try{
      dispatch(saveDate({
        dateRange: observedAt,
        startDate: startDate,
        endDate: endDate
      }));
    } catch(error) {
      console.log(error);
    }
  }

  const getData = (e, q, date) => {
    try {
      setNoRecord(false);
      setEnableExport(false);
      setAnnotations([]);
      setGraphEvent('');
      setStart(e.start);
      setEnd(e.end);
      setAreaQuery(q ? q : '');
      setQueryDate(date);
      setQueryData([]);
      dispatch(saveSearchData([])); 
      dispatch(saveAreaQuery(''));
      dispatch(saveImageKeys([]));
      const payload = {
        body: JSON.stringify({
          index: indexValue,
          alias: aliasFlag ? indexAlias : '',
          groups: q,
          date: date,
          chart: e.chart,
          mappingKey: mappingImageKey
        })
      };
      getSearcData(payload).then(resp => {
        if(resp?.data?.preloads){
          dispatch(saveImageLoadCount(resp.data.preloads));
        }
        if(resp?.data?.data){
          const data = resp.data.data;
          setFilterValue([]);
          setLowerRange('');
          setHigherRange('');
          setEnableExport(true);
          if (mappingImageKey === '') {
            setIsLoading(false);
            setEnableExport(false);
            notification.error({
              message: 'Please select image key',
            });
            return;
          }
          if(data?.aggregations?.images_count?.buckets){
            if (data.aggregations.images_count.buckets.length > 0) {
              const graphArray = [];
              data.aggregations.images_count.buckets.forEach(obj => {
                const graphObj = [];
                graphObj.push(obj.key, obj.doc_count);
                graphArray.push(graphObj);
              });
              setGraphdata(graphArray);
            }else{
              setGraphdata([])
            }
          }
          if(data?.hits?.hits){
            if (data.hits.hits.length > 0) {
              const sourceData = [];
              let dataIds = [];
              data.hits.hits.forEach(element => {
                sourceData.push(element._source); 
                dataIds.push(element._id);
              });
              if(dataIds.length){
                dataIds = [...new Set(dataIds)];
              }
              dispatch(saveAllDataIds(dataIds));
              dispatch(saveSearchData(sourceData));
              dispatch(savePddDetail({
                status: pddDetail?.status ? pddDetail.status : false, 
                dataLength: sourceData?.length ? sourceData.length : 0,
                keys: pddDetail?.keys ? pddDetail.keys : ''
              }));
              dispatch(configureFieldStatus(true));
              setQueryData(sourceData);
              let esOptionsArray = [];
              if(sourceData.length){
                let screenData = sourceData[0];
                if(screenData){
                  const dataArr = Object.keys(screenData);
                  let id = 1;
                  if(dataArr.length){
                    dataArr.forEach(signatureKey => {
                      let filterImgDetail = "";
                      if(imageDetail.length){
                        filterImgDetail = imageDetail.find(val => val.value === signatureKey);
                      }
                      if(filterImgDetail && filterImgDetail.type !== "nested"){
                        esOptionsArray.push({
                          id: id++,
                          value: signatureKey,
                          isChecked: false,
                          type: filterImgDetail.type ? filterImgDetail.type : "" 
                        });
                      }
                      
                    });
                  }
                }
              }

              esOptionsArray = sortBy(esOptionsArray, 'value');
              const optionsObj = cloneDeep(esOptionsArray);
              optionsObj.forEach((option)=>{
                option.isChecked = configuredFields.some(el => el.label === option.value) ? true : option.isChecked;
              })
              setSelectedOption(optionsObj);
              const checkFieldStatus = optionsObj.filter(val => val.isChecked === true);
              if(checkFieldStatus?.length){
                dispatch(configureFieldStatus(true));
              } else {
                dispatch(configureFieldStatus(false));
              }
            } else {
              if (indexValue !== ''){
                setIsLoading(false);
                setEnableExport(false);
                notification.error({
                  message: 'No images found from search query'
                });
              }
            }
          }
        } else {
          notification.error({
            description: "Something went wrong. Please try later.",
            duration: 10
          })
          setIsLoading(false);
          setEnableExport(false);
        }
        dispatch(setEnableSearch(true));
      })
      .catch(error => {
        if(error?.response?.data?.message && error?.response?.data?.statusCode !== 401){
          notification.error({
            description: error.response.data.message === "Cannot read property 'type' of undefined" ? "Please make sure you have selected the correct elastic index." : "Something went wrong. Please try later.",
            duration: 10
          })
          setIsLoading(false);
          setEnableExport(false);
        }
        dispatch(setEnableSearch(true));
      })
    } catch (error) {
      setIsLoading(false);
      setEnableExport(false);
    }
  }

  return (
    <EuiSuperDatePicker onTimeChange={onTimeChange} start={start} end={end} />
  );

}

export default DateTimePicker;
