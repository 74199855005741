import { Button, Col, Row, Checkbox, Switch, Tooltip, Popover, Badge, Typography, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import SourceInfo from "../SourceInfo";
import { FilterOutlined, CaretDownFilled, PicLeftOutlined, PictureOutlined, FileTextOutlined } from '@ant-design/icons';
import DateTimePicker from "../DateTimePicker";
import "./search.scss";
import AddFilter from "../FilterPopUp/AddFilter";
import EditFilter from "../FilterPopUp/editFilter";
import UpdateSignature from "../FilterPopUp/updateSignature";
import { useDispatch, useSelector } from "react-redux";
import { saveFilters, saveGraphVisibility, savePddDetail, saveQueryStack, setView } from "../../store/actions";
import ExportImages from '../Export';
import QueryStackComponent from "../QueryStack";
import ExportQuery from "../ExportQuery/exportQuery";
import ImportQuery from "../ImportQuery/importQuery";
import ImportFile from "../ImportQuery/importFile";
import PddPopover from "../PDD/pddPopover";

const { Link } = Typography;

const Search = (props) => {
    const dispatch = useDispatch();
    const {pddDetail, selectedBucket, view, maskedBucket, queryStack, filters, graphVisibility} = useSelector(store => store.storeProps);
    const {queryData, setQueryData, setIsLoading, isLoading, setEnableExport, setSelectedOption, selectedOption, setAreaQuery, setGraphdata,setQueryDate, setAnnotations, enableExport, setGraphVisible, setGraphEvent, graphEvent, setNoRecord, norecords, isDataLoading, setFinalQuery, finalQuery
    } = props;
    const [lowerRange, setLowerRange] = useState('');
    const [higherRange, setHigherRange] = useState('');
    const [filterValue, setFilterValue] = useState([]);
    const [addFilter, setAddFilter] = useState(false);
    const [isFieldVisible, setFieldsVisible] = useState(false);
    const [isFiltersVisible, setFiltersVisible] = useState(false);
    const [isExportVisible, setExportVisible] = useState(false);
    const [isPddVisible, setPddVisible] = useState(false);
    const [queryString, setQueryString] = useState(filters.length ? filters : '');
    const [activeAll, setActiveAll] = useState(true);
    const [isValueMatch, setIsValueMatch] = useState(false);
    const [importFile, setImportFile] = useState(false);
    const [editLoader, setEditLoader] = useState(false);

    let checkedFieldsCount = selectedOption.filter(val => {
        return val.isChecked
    })

    let checkedFiltersCount = queryStack.filter(val => {
        return val.isChecked
    })
 
    useEffect(() => {
        try{
            let qs = '';
            let finalQ = '';
            let filtersArr = [];
            let andFilters = [];
            let pddEnable = false;
            let pok = pddDetail?.keys ? pddDetail.keys.indexOf(",") !== 1 ? pddDetail.keys.split(",") : [pddDetail.keys] : [];
            if(queryStack.length){
                queryStack.forEach((val) => {
                    if(val.isChecked === true){
                        if(pok.length){
                            for(let i=0;i<pok.length;i++){
                                if(pok[i] === val.field){
                                    pddEnable = true;
                                    break;
                                }
                            }
                        }
                        if(val.operand === 'OR'){
                            qs = qs ? `${qs} AND ${val.query})` : `(${val.query})`;
                            andFilters.push({
                                key: val.field,
                                value: val.fieldValue,
                                operator: val.operator,
                                operand: val.operand
                            });
                            filtersArr.push(andFilters);
                            andFilters = [];
                            finalQ = finalQ ? `${finalQ} OR ${qs}` : qs;
                            qs = '';
                        } else {
                            qs = qs ? `${qs} AND ${val.query}` : `(${val.query}`;
                            andFilters.push({
                                key: val.field,
                                value: val.fieldValue,
                                operator: val.operator,
                                operand: val.operand
                            });
                        }
                    }
                })
                if(andFilters.length){
                    filtersArr.push(andFilters);
                }
                for(let i = 0; i < queryStack.length; i++){
                    if(queryStack[i].isChecked === true){
                        setActiveAll(true);
                    } else {
                        setActiveAll(false);
                        break;
                    }
                }
            }
            dispatch(savePddDetail({status: pddEnable, dataLength: 0, keys: pddDetail?.keys ? pddDetail.keys : ''}));
            finalQ = finalQ ? `${finalQ} ${qs ? `OR ${qs})` : ''}` : `${qs ? `${qs})` : ''}`;
            setFinalQuery(finalQ);
            if(!finalQ){
                setIsVisible(false);
                setUpdateSignatureFlag(false);
                setImportFile(false);
            }
            setQueryString(filtersArr);
            dispatch(saveFilters(filtersArr));
        } catch(error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, [queryStack])
    const [isVisible, setIsVisible] = useState(false);
    const showFinalQuery = () => {
        setIsVisible(isVisible ? false : true);
    }
   
    const onCheckedAll = (e) => {
        try{
            if(e && e.target?.checked){
                updateQStack(true);
            } else {
                updateQStack(false);
            }
            setActiveAll(e.target.checked)
        } catch(error) {
            console.log(error);
        }
    }
    const updateQStack = (e) => {
        try{
            if(queryStack.length){
                const newStack = queryStack.map((val, i) => {
                    if(typeof e === 'boolean'){
                        val = {...val, isChecked : e};
                    }
                    return val;
                })
                if(newStack?.length && typeof e === 'boolean'){
                    newStack.forEach((val, i) => {
                        if(e){
                            if(val.operand === 'OR' && i > 0){
                                newStack[i].group = true;
                                newStack[i].allowOperand = true;
                            } else {
                                if(val.braces !== ''){
                                    newStack[i].group = true;
                                    newStack[i].allowOperand = false;
                                } else {
                                    newStack[i].group = false;
                                    newStack[i].allowOperand = true;
                                }
                            }
                        } else {
                            if(val.operand === 'OR' && i > 0){
                                newStack[i].allowOperand = false;
                            } else {
                                if(val.braces !== ''){
                                    newStack[i].group = false;
                                } else if(i > 0){
                                    newStack[i].allowOperand = false;
                                }
                            }
                        }
                    })
                }
                dispatch(saveQueryStack(newStack));
            }
        } catch(error) {
            console.log(error);
        }
    }

    const [editFilter, setEditFilter] = useState(false);
    const [queryDetailArr, setQueryDetailArr] = useState([]);
    const [editIndex, setEditIndex] = useState('');
    const [editInitialVals, setEditInitialVals] = useState("");
    const [updateSignatureFlag, setUpdateSignatureFlag] = useState(false);
    const handleCancel = () => {
        try{
            setEditIndex('');
            setValidaton({isValidate: true, message: ''})
            setEditFilter(false);
            setUpdateSignatureFlag(false);
            setImportFile(false);
            setEditInitialVals("");
        } catch(error) {
            console.log(error);
        }
    };
    const handleUpdateSignature = () => {
        try{
            setUpdateSignatureFlag(true);
            setAddFilter(false);
            setEditFilter(false);
            setImportFile(false);
        } catch(error) {
            console.log(error);
        }
    }
    const [validation, setValidaton] = useState({isValidate: true, message: ''})
   
    const handleAddFilter = () =>{
        try{
            setAddFilter(true);
            setEditFilter(false);
            setUpdateSignatureFlag(false);
            setImportFile(false);
        } catch(error) {
            console.log(error);
        }
    }

    const renderFields = () =>{
        return (
            <SourceInfo  selectedOption={selectedOption} setSelectedOption={setSelectedOption}/>
        )
    }

    const popoverVisible = () => {
        setFiltersVisible(!isFiltersVisible);
        if(!isFieldVisible){
            setAddFilter(false);
            setEditFilter(false);
            setUpdateSignatureFlag(false);
            setImportFile(false);
        }
    }
    const filterWrapper = useRef(null);
    const filterSection = useRef(null);
    useEffect(() => {
        if(addFilter || editFilter || updateSignatureFlag || importFile){
            handleScroll();
        }
    }, [addFilter, editFilter, updateSignatureFlag, importFile])
    const handleScroll = () => {
        try{
            if(filterWrapper && filterSection){
                const popupDiv = filterWrapper.current;
                const formDiv = filterSection.current;
                if(formDiv.offsetTop && formDiv.offsetTop > 300){
                    popupDiv.scrollTo(0, popupDiv.scrollHeight)
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

    
    const renderFilter = () => {
        return (
            <div className="pop-filters" ref={filterWrapper}>
                {editLoader && (
                    <>
                        <div className="editLoaderClass">
                            <div className="loaderIcon"><Spin  className="loader-icon" /><br></br>Please wait</div>
                        </div>
                    </>
                )}
                <Row><Col span={24}><strong>Filters</strong></Col></Row>
                {isDataLoading ? (
                    <>
                        <div className="noFilters">
                            <Spin  className="loader-icon" />
                            Please wait images is still loading in carousel...
                        </div>
                    </>
                ) : (
                    <>
                        <Row className={`${queryStack.length > 0 ? 'query-wrapper': ''}`}>
                            <Col span={24}>
                                {queryStack.length > 0 ? (
                                    <QueryStackComponent 
                                        queryStack={queryStack}
                                        setEditIndex={setEditIndex}
                                        setQueryDetailArr={setQueryDetailArr}
                                        setEditFilter={setEditFilter}
                                        setAddFilter={setAddFilter}
                                        setUpdateSignatureFlag={setUpdateSignatureFlag}
                                        setEditInitialVals={setEditInitialVals}
                                        setIsValueMatch={setIsValueMatch}
                                        setImportFile={setImportFile}
                                        setEditLoader={setEditLoader}
                                    />
                                ):(
                                    <div className="noFilters">
                                        <img src="./no-filter.png" className="no-filetrs-logo" alt="FIQ" />
                                        No Filter Added
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row className={`query-content-wrapper`}>
                            <Col span={24}>
                                <div className={`${isVisible ? 'showQuery' : 'hideQuery'}`}>
                                    <div className={`finalQuery`}><p>{finalQuery}</p></div>
                                </div>
                            </Col>
                        </Row>
                        <Row className={`filters-footer`}>
                            <Col span={7}>
                                {queryStack.length > 0 && (
                                    <ul className="queryList">
                                        <li className="queryButton">
                                            <Button type="primary" disabled={finalQuery ? false : true} onClick={showFinalQuery}>
                                                {isVisible ? "Hide Query" : "Show Query"}
                                            </Button>
                                        </li>
                                        {queryStack.length > 1 && (
                                            <li className="checkedAllButton">
                                                <Checkbox id="filtersCheckbox" checked={activeAll} onChange={onCheckedAll}>
                                                    {activeAll ? "Uncheck All" : "Check All"}
                                                </Checkbox>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </Col>
                            <Col span={17} className="add-filter-btn">
                                <ImportQuery setImportFile={setImportFile} setUpdateSignatureFlag={setUpdateSignatureFlag} setAddFilter={setAddFilter} setEditFilter={setEditFilter}/>
                                {queryStack.length > 0 && (
                                    <ExportQuery queryStack={queryStack} enableExport={enableExport} finalQuery={finalQuery}/>
                                )}
                                {queryStack.length > 0 && (
                                    <Button id="updateSignature" disabled={finalQuery ? enableExport ? false : true : true} type="primary" onClick={handleUpdateSignature} style={{marginTop: '5px'}}>
                                        Update Signature
                                    </Button>
                                )}
                                <Button type="primary" className="btnAddFilter" onClick={handleAddFilter} style={{marginTop: '5px'}}>
                                    Add Filter
                                </Button>
                            </Col>
                        </Row>
                        <Row ref={filterSection}>
                            <Col span={24}>
                                {addFilter && (
                                    <AddFilter
                                        lowerRange={lowerRange}
                                        setLowerRange={setLowerRange}
                                        higherRange={higherRange}
                                        setHigherRange={setHigherRange}
                                        setFilterValue={setFilterValue}
                                        filterValue={filterValue}
                                        isLoading={isLoading}
                                        queryStack={queryStack}
                                        setAddFilter={setAddFilter}
                                    />
                                )}
                                {editFilter && (
                                    <EditFilter
                                        queryDetailArr={queryDetailArr}
                                        editInitialVals={editInitialVals}
                                        validation={validation}
                                        queryStack={queryStack}
                                        editIndex={editIndex}
                                        setValidaton={setValidaton}
                                        handleCancel={handleCancel}
                                        isValueMatch={isValueMatch}
                                        setIsValueMatch={setIsValueMatch}
                                    />
                                )}
                
                                {updateSignatureFlag && (
                                    <UpdateSignature
                                        handleCancel={handleCancel}
                                        setUpdateSignatureFlag={setUpdateSignatureFlag}
                                    />
                                )}

                                {importFile && (
                                    <ImportFile
                                        queryStack={queryStack} 
                                        handleCancel={handleCancel}
                                        setImportFile={setImportFile}
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        )
    }

    const renderExport = () => {
        return (
            <ExportImages queryData={queryData} enableExport={enableExport} setEnableExport={setEnableExport} />
        )
    }

    const renderPddPopover = () => {
        return (
            <PddPopover setPddVisible={setPddVisible} />
        )
    }

    const disablePopover = queryData.length === 0 ? 'disable-popup':'';
    const noPointerEvents = queryData.length === 0 ? 'pointer-events-none':'';

    const [viewFlag, setViewFlag] = useState(false);
    const renderViews = () => {
        return (
            <ul className="renderViewList">
                <li className={(view === 'bounding-box') ? 'active': ''} onClick={() => dispatch(setView('bounding-box'))}><span></span>Bounding Box</li>
                <li className={(view === 'annotation') ? 'active': ''} onClick={() => dispatch(setView('annotation'))}><span></span>Annotation View</li>
                {maskedBucket && (
                    <li className={(view === 'masked') ? 'active': ''} onClick={() => dispatch(setView('masked'))}><span></span>Masked View</li>
                )}
            </ul>
        )
    }
    const handleGraph = (checked) => {
        dispatch(saveGraphVisibility(checked));
        setGraphVisible(checked);
    }
    return (
        <>
            <Row className="search-header">
                {selectedBucket?.bucketName && (
                    <Col flex="1 1 0" className="datepicker">
                        <div  className="overflow-hidden hide-text">
                            <DateTimePicker
                                queryString={queryString}
                                setQueryString={setQueryString}
                                queryStack={queryStack}
                                setGraphdata={setGraphdata}
                                setQueryData={setQueryData}
                                setSelectedOption={setSelectedOption}
                                setLowerRange={setLowerRange}
                                setHigherRange={setHigherRange}
                                setFilterValue={setFilterValue}
                                setAreaQuery={setAreaQuery}
                                setQueryDate={setQueryDate}
                                setAnnotations={setAnnotations}
                                setGraphEvent={setGraphEvent}
                                graphEvent={graphEvent}
                                setIsLoading={setIsLoading}
                                isLoading={isLoading}
                                setEnableExport={setEnableExport}
                                setNoRecord={setNoRecord}
                            />
                        </div>
                    </Col>
                )}
                
                {!norecords && (
                    <>
                        <Col flex="auto" className={`align-content ${disablePopover} ${isFieldVisible ? 'highlight-field': ''}` } >
                            <Popover
                                content={renderFields()}
                                trigger="click"
                                visible={isFieldVisible}
                                placement="bottom"
                                onVisibleChange={() => { setFieldsVisible(!isFieldVisible)}}
                                className={`overflow-hidden ${noPointerEvents} ${isFieldVisible ? 'active': ''}`} 
                            >
                                <PicLeftOutlined />
                                Configure Field
                                <Badge className="filters-badge" count={checkedFieldsCount.length} />

                                <CaretDownFilled />
                            </Popover>
                        </Col>
                        <Col flex="auto" className={`align-content ${isFiltersVisible ? 'highlight-field': ''}` }>
                            <Popover
                                content={renderFilter()}
                                trigger="click"
                                visible={isFiltersVisible}
                                placement="bottom"
                                onVisibleChange={popoverVisible}
                                className={`overflow-hidden ${isFiltersVisible ? 'active': ''}`}
                            >
                            <FilterOutlined />
                            Filters
                            <Badge className="filters-badge" count={checkedFiltersCount.length} />
                            <CaretDownFilled />

                            </Popover>
                        </Col>
                        <Col flex="auto" className={`align-content ${viewFlag ? 'highlight-field': ''}` }>
                            <Popover
                                content={renderViews()}
                                trigger="click"
                                visible={viewFlag}
                                placement="bottom"
                                onVisibleChange={() => setViewFlag(!viewFlag)}
                                className={`overflow-hidden ${viewFlag ? 'active': ''}`}
                            >
                            <FileTextOutlined />
                            Carousel View
                            <CaretDownFilled />

                            </Popover>
                        </Col>
                        <Col flex="auto" className={`align-content ${disablePopover}` } >
                            <div  className={`overflow-hidden  ${noPointerEvents}`}>
                             Graph
                            <Switch size="small" checkedChildren="Hide" unCheckedChildren="Show" defaultChecked={graphVisibility} style={{fontSize:'14px', backgroundColor: '#F05742', marginLeft:'5px'}} onClick={(checked) => handleGraph(checked)} />
                            </div>
                        </Col>
                        <Col flex="auto" className={`align-content flex-end ${disablePopover}`} >
                            {pddDetail?.status && pddDetail?.dataLength < 200 ? (
                                <Tooltip placement="bottom" arrowPointAtCenter title="Process Definition Document" className={` ${noPointerEvents} `}>
                                    <Link href="/pdd" className="btnPDD" target="_blank"><FileTextOutlined /></Link>
                                </Tooltip>
                            ) : (
                                <Popover
                                    content={renderPddPopover()}
                                    trigger="click"
                                    visible={isPddVisible}
                                    placement="bottomRight"
                                    onVisibleChange={() => { setPddVisible(!isPddVisible)}}
                                    className={`overflow-hidden ${noPointerEvents} ${isPddVisible ? 'active': ''}`} 
                                >
                                    <Tooltip placement="bottom" arrowPointAtCenter title="Process Definition Document">
                                        <Button className="btnExport" type="primary"  size="large"  icon={<FileTextOutlined />} />
                                    </Tooltip>
                                </Popover>
                            )}
                            
                            
                            <Popover
                                content={renderExport()}
                                trigger="click"
                                visible={isExportVisible}
                                placement="bottomRight"
                                onVisibleChange={() => { setExportVisible(!isExportVisible)}}
                                className={`overflow-hidden  ${noPointerEvents} ${isExportVisible ? 'active': ''}`} 
                            >
                                <Tooltip placement="bottom" arrowPointAtCenter title="Export Images">
                                    <Button className="btnExport" type="primary"  size="large"  icon={<PictureOutlined />} />
                                </Tooltip>
                            </Popover>
                        </Col>
                    </>
                )}
            </Row>
        </>
    )
}

export default Search;