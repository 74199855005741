import { Button, Tooltip, Select } from "antd";
import { SettingOutlined, EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { saveBuckets, saveSelectedBucket, saveMaskedBucket, setEnableSearch, saveMaskedFlag, saveMinioClient } from "../../store/actions";
import ROOT from '../../client';
import AddBucket from "./addBucket";
import { getMinioClient } from "../../services";
import axiosConfig from "../../axiosConfig";

const { Option } = Select;

const Bucket = (props) => {
    const dispatch = useDispatch();
    const { setIsLoading } = props;
    const { buckets, selectedBucket, maskedBucket, fileConfigurations, envars } = useSelector(store => store.storeProps);
    const [bucketModalVisible, setBucketModalVisible] = useState(false);
    const [bucketsOption, setBucketsOptions] = useState([]);
    const [updateFlag, setUpdateFlag] = useState(true);
    const [disableListbox, setDisableListbox] = useState(false);
    const [disableMasked, setDisableMasked] = useState(true);
    const [defaultBucket, setDefaultBucket] = useState(selectedBucket && selectedBucket.bucketName ? selectedBucket.bucketName : "");
    const [configureBucket, setConfigureBucket] = useState("");
    const [selectedMaskedBucket, setSelectedMaskedBucket] = useState(maskedBucket && maskedBucket.bucketName ? maskedBucket.bucketName : "");
    const configflag = envars?.storageMechanism === 'minio' || envars?.storageMechanism === 'aws' ? envars?.bucketConfigAccess ? true : false : true;

    useEffect(() => {
        if(defaultBucket){
            dispatch(setEnableSearch(true));
            setDisableMasked(false); 
        }
        // eslint-disable-next-line
    }, [])

    const storeBuckets = (data) => {
        try{
            if(data.length){
                dispatch(saveBuckets(data));
                const bucketOpts = []; 
                data.forEach(val => {
                    if(val?.bucketName){
                        bucketOpts.push(val.bucketName);
                    }
                })
                setBucketsOptions(bucketOpts?.length ? bucketOpts : []);
                setIsLoading(false);
            }
        } catch(error) {
            setIsLoading(false);
            setUpdateFlag(false);
            console.log(error);
        }
    }

    const getBuckets = () => {
        setIsLoading(true);
        axiosConfig.get(`${ROOT}/api/bucket-names`).then(res => {
            if(res?.data?.data.length){
                storeBuckets(res?.data?.data);
            } else {
                setIsLoading(false);
            }
            setUpdateFlag(false);
        })
        .catch(error => {
            setIsLoading(false);
            setUpdateFlag(false);
            console.log(error);
        })
    }

    useEffect(() => {
        try{
            if(updateFlag){
                if(!defaultBucket && (fileConfigurations || configureBucket)){
                    setIsLoading(true);
                }
                if(defaultBucket && configureBucket){
                    setIsLoading(true);
                }
                if(envars?.storageMechanism === "minio" && !envars?.bucketConfigAccess){
                    setIsLoading(true);
                    const mc = getMinioClient({
                        endPoint: envars?.minioEndPoint ? envars.minioEndPoint : "storage-fiq-53e0863f-82db-4403.fiq-dev.com",
                        port: envars?.minioPort ? Number(envars.minioPort) : 443,
                        useSSL: envars?.useSsl ? envars.useSsl : true,
                        accessKey: envars?.minioAccessKey ? envars.minioAccessKey : "fortressiq",
                        secretKey: envars?.minioSecretKey ? envars.minioSecretKey : "mn2smDXjJW9cWbrpG5"
                    })
                    dispatch(saveMinioClient({firstBucket: mc, secondBucket: mc}));
                    mc.listBuckets().then(res => {
                        if(res){
                            const bucketData = res.map(row => {
                                return {
                                    bucketFolderName: "",
                                    bucketName: row.name,
                                    metaInfo: ""
                                }
                            })
                            storeBuckets(bucketData.length ? bucketData : []);
                            setIsLoading(false);
                        } else {
                            setIsLoading(false);
                        }
                    })
                    .catch(error => {
                        setIsLoading(false);
                        console.log(error);
                    });
                } else {
                    setTimeout(() => {
                        getBuckets();
                    }, 1000)
                }
            }
        } catch(error) {
            setIsLoading(false);
            setUpdateFlag(false);
            console.log(error);
        }
        // eslint-disable-next-line
    }, [updateFlag]);

    const saveBucket = (bName, bucketType) => {
        try{
            if(buckets.length && bName){
                const bucketInfo = buckets.find(item => item.bucketName === bName);
                if(bucketInfo){
                    if(bucketType === "1"){
                        dispatch(saveSelectedBucket(bucketInfo));
                    } else {
                        dispatch(saveMaskedBucket(bucketInfo));
                    }
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

    const buckethandler = (value) => {
        try{
            if(value){
                setDisableListbox(true);
                setDefaultBucket(value);
                setConfigureBucket("");
                saveBucket(value, "1")
                if(!selectedMaskedBucket){
                    setDisableMasked(false);
                }
            }
            if(!defaultBucket && (fileConfigurations || configureBucket)){
                setIsLoading(false);
            }
            if(defaultBucket && configureBucket){
                setIsLoading(false);
            }
        } catch(error) {
            console.log(error);
        }
    }
    const maskedBuckethandler = (value) => {
        try{
            if(value){
                setSelectedMaskedBucket(value);
                setDisableMasked(true);
                setConfigureBucket("");
                saveBucket(value, "2")
                dispatch(saveMaskedFlag(true));
            }
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if(bucketsOption?.length && !defaultBucket){
            const findBucketOption = bucketsOption.filter(val => val === fileConfigurations.bucketName);
            if(findBucketOption?.length){
                buckethandler(fileConfigurations.bucketName);
            }
        }
        if(configureBucket){
            if(selectedMaskedBucket === configureBucket){
                maskedBuckethandler(configureBucket);
            } else {
                buckethandler(configureBucket);
            }
        }
        // eslint-disable-next-line
    }, [bucketsOption]);

    return (
        <>
            <div className="bucketInfo">
                <div className="changeBucketWrapper">
                    <div className="bucketList">
                        <span className="bucketLabel">Bucket 1: <small>(Mandatory)</small></span>
                        <Select onSelect={buckethandler} value={defaultBucket} id="bucketsList" showSearch disabled={disableListbox} placeholder="Select Bucket">
                            {bucketsOption?.length > 0 && (
                                <>
                                    {bucketsOption.map(name => {
                                        return (
                                            <Option key={name} value={name}>{name}</Option>
                                        )
                                    })}
                                </>
                            )}
                        </Select>
                        {selectedBucket?.bucketName && disableListbox && (
                            <Tooltip placement="bottom" title="Change Bucket">
                                <Button className="addBucketBtn changeBucketBtn" icon={<EditOutlined />} onClick={() => setDisableListbox(false)}/>
                            </Tooltip>
                        )}
                    </div>
                    <div className="bucketList">
                        <span className="bucketLabel">Bucket 2: <small>(Optional)</small></span>
                        <Select onSelect={maskedBuckethandler} value={selectedMaskedBucket} id="maskedbucketList" showSearch disabled={disableMasked} placeholder="Select Masked Bucket">
                            {bucketsOption?.length > 0 && (
                                <>
                                    {bucketsOption.map(name => {
                                        return (
                                            <Option key={`${name}_masked`} value={name}>{name}</Option>
                                        )
                                    })}
                                </>
                            )}
                        </Select>
                        {maskedBucket?.bucketName && disableMasked && (
                            <Tooltip placement="bottom" title="Change Bucket">
                                <Button className="addBucketBtn changeBucketBtn" icon={<EditOutlined />} onClick={() => setDisableMasked(false)}/>
                            </Tooltip>
                        )}
                    </div>
                </div>
                {configflag && (
                    <div className="configureBucketWrapper">
                    <Tooltip placement="bottom" title="Configure Bucket">
                        <Button className="addBucketBtn" icon={<SettingOutlined />} onClick={() => setBucketModalVisible(true)} type="primary">Configure</Button>
                    </Tooltip>
                </div>
                )}
            </div>
            <AddBucket bucketModalVisible={bucketModalVisible} setBucketModalVisible={setBucketModalVisible} bucketsOption={bucketsOption} setUpdateFlag={setUpdateFlag} setConfigureBucket={setConfigureBucket}/>
        </>
    )
}

export default Bucket;