

import React from 'react';
import { Spin } from 'antd';
import './style.scss';
 
const Loader = () =>{
    return (
        <div className="loader-wrapper">
            <div className="loader-contents">
                <img className="loader-logo" src="./fortress-icon.png" alt="FIQ" />
                <div className="load-icon-wrapper">
                <Spin  className="loader-icon" />
                <span className="loading-label">Loading ...</span>
                </div>
            </div>
        </div>
    )
}

export default Loader;