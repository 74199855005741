import React from 'react';
import { Button } from 'antd';
import "./importQuery.scss";
import { ImportOutlined } from '@ant-design/icons';

const ImportQuery = (props) => {
    const {setImportFile, setUpdateSignatureFlag, setAddFilter, setEditFilter} = props;
    const handleImport = () => {
        setImportFile(true)
        setUpdateSignatureFlag(false);
        setAddFilter(false);
        setEditFilter(false);
    }
    return (
        <>
           <Button id="importQueryButton" icon={<ImportOutlined />} type="primary" onClick={handleImport} style={{marginTop: '5px'}}>Import Query</Button>
        </>
    )
}

export default ImportQuery;