import React from 'react'
import { notification, Menu, Typography } from "antd";
import { ProfileOutlined, FormOutlined, PictureOutlined,PoweroffOutlined,SettingOutlined, UserOutlined } from '@ant-design/icons';
import ROOT from "../../client"
import "./settings.scss";
import { useHistory } from "react-router-dom";
import { useDispatch,useSelector } from 'react-redux';
import { saveSearchData, saveAllDataIds, saveDate, saveFilters, saveAreaQuery, updateEnvarsStatus, saveImageKeys, saveMinioClient, setEnableSearch, saveMaskedFlag, saveUuids, saveSessionFlag } from "../../store/actions";
import axiosConfig from '../../axiosConfig';

const { Link } = Typography;
const { SubMenu } = Menu;

const Settings = (props) => {
    const {indexValue, indexAlias, aliasIndexesArr, mappingImageKey} = useSelector(store => store.storeProps);
    const allAliasIndexes = aliasIndexesArr && aliasIndexesArr.length ? aliasIndexesArr : [];
    const dispatch = useDispatch();
    const { setIsLoading, setVisibleMenu } = props;
    let history = useHistory();

    const logoutHandler = (source) => {
        try{
            setIsLoading(true);
            dispatch(saveSearchData([]));
            dispatch(saveAllDataIds([]));
            dispatch(saveDate(''));
            dispatch(saveFilters(''));
            dispatch(saveAreaQuery(''));
            dispatch(saveImageKeys([]));
            dispatch(saveMinioClient(""));
            dispatch(setEnableSearch(false));
            dispatch(saveMaskedFlag(false));
            dispatch(saveSessionFlag(false))
            if(source === '/'){
                dispatch(updateEnvarsStatus(true));
            }
            const apiPath = `${ROOT}/api/logout`;
            axiosConfig.post(apiPath).then(resp => {
                if (resp?.data?.statusCode === 200) {
                    setIsLoading(false);
                    dispatch(saveUuids({}))
                    history.push(source);
                }
            }).catch(error => {
                setIsLoading(false);
                dispatch(saveUuids({}))
                console.log(error);
                notification.error({
                message: error?.response?.data?.message
                });
                return;
            });
        } catch(error) {
            console.log(error);
        }
    }

    const handleMenuOnclick = () => {
        setVisibleMenu(false);
    }
    return (
        <>
           <Menu className="profile-settings" onClick={handleMenuOnclick}>
                <Menu.Item key="0" className="menu-item-wrapper bottomBorder disabled-item">
                    <UserOutlined />
                     My Profile
                </Menu.Item>
                <Menu.Item key="1" className="menu-item-wrapper disabled-item">
                    <ProfileOutlined rotate={180} />
                    Index Pattern: <span className="menu-item-val">{indexValue}</span>
                </Menu.Item>
                <Menu.Item key="2" className="menu-item-wrapper edit-indices-wrapper">
                    <Link href="/indices" ><FormOutlined className="edit-indices" style={{color: '#F05742'}} /></Link>
                </Menu.Item>
                {indexAlias && (
                    <>
                        {allAliasIndexes.length > 0 ? (
                            <>
                                <SubMenu key="sub1" className="aliasSubmenu" icon={<ProfileOutlined rotate={180} />} title={<>Alias: <span className="aliasValue">{indexAlias}</span></>}>
                                    {allAliasIndexes.map(val => {
                                        return (
                                            <Menu.Item key={val}>{val}</Menu.Item>
                                        )
                                    })}
                                </SubMenu>
                            </>
                        ) : (
                            <Menu.Item key="aliasMenu" className="menu-item-wrapper disabled-item">
                                <ProfileOutlined rotate={180} />
                                Alias: <span className="menu-item-val">{indexAlias}</span>
                            </Menu.Item>
                        )}
                        <Menu.Item key="4" className={`menu-item-wrapper edit-indices-wrapper ${allAliasIndexes.length > 0 ? 'editAlias' : 'editAliasMenu'}`}>
                            <Link href="/indices" ><FormOutlined className="edit-indices" style={{color: '#F05742'}} /></Link>
                        </Menu.Item>
                    </>
                )}
                <Menu.Item key="5" className="menu-item-wrapper bottomBorder disabled-item">
                    <PictureOutlined />
                    Image Key: <span className="menu-item-val">{mappingImageKey}</span>
                </Menu.Item>
                <Menu.Item key="6" className="menu-item-wrapper " onClick={() => logoutHandler('/')}>
                    <SettingOutlined />
                    Update Configuration
                </Menu.Item>
                <Menu.Item key="7" className="menu-item-wrapper logOutBtn" onClick={() => logoutHandler('/login')}>
                    <PoweroffOutlined />
                    Logout
                </Menu.Item>
            </Menu>
        </>
    )
}
export default Settings;