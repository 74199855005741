import axiosConfig from "../axiosConfig";
import ROOT from "../client";
const Minio = require("minio");

export const getSearcData = (payload) => {
    return axiosConfig.post(`${ROOT}/api/get-search-res`, payload);
}

export const getAllSuggestions = (payload) => {
    return axiosConfig.post(`${ROOT}/api/get-all-values`, payload);
}

export const getMinioClient = (payload) => {
    const minioClient = new Minio.Client(payload)
    return minioClient;
}