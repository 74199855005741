import { Form, Button, Input, Upload, Switch, notification } from "antd";
import { QuestionCircleFilled, UploadOutlined } from '@ant-design/icons';
import React, { useState } from "react";
import ROOT from '../../client';
import { useSelector } from 'react-redux';
import * as helper from "../helper";
import axiosConfig from '../../axiosConfig'

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 14 },
};

const ConfigureBucket = (props) => {
    const [form] = Form.useForm();
    const { envars, selectedBucket, uuids } = useSelector(store => store.storeProps);
    const { setBucketModalVisible, setUpdateFlag, initialVals, setConfigureBucket } = props;
    const [loading, setLoading] = useState(false);
    const [useChecked, setUseChecked] = useState(true);
    const [filesList, setFiltsList] = useState([]);
    const [fileError, setFileError] = useState({
        status: false,
        message: '',
        className: "errorMessage"
    });
    const token = uuids?.uuid1;
    
    const handleCancel = () => {
        setBucketModalVisible(false);
        form.resetFields();
        setFiltsList([]);
        fileValidationMsg(false, '', 'errorMessage');
    };

    const onConfigure = (values) => {
        try{
            if(values){
                let isFileUploaded = true;
                if(values.gcpGoogleAppCredentials && filesList?.length){
                    const filestamp = values.gcpGoogleAppCredentials.file?.response?.fileName ? values.gcpGoogleAppCredentials.file.response.fileName : '';
                    values.fileName = filestamp ? filestamp : '';
                    delete values.gcpGoogleAppCredentials;
                } else if(envars?.storageMechanism === 'gcp'){
                    isFileUploaded = false;
                }
                let bname = values.bucketName;
                if(values.bucketFolderName){
                    values.bucketName = `${values.bucketName}/${values.bucketFolderName}`;
                    delete values.bucketFolderName;
                }
                if(isFileUploaded){
                    setLoading(true);
                    axiosConfig.post(`${ROOT}/api/bucket-names`, values).then(res => {
                        if(res){
                            if(selectedBucket.bucketName){
                                if(selectedBucket.bucketName !== bname){
                                    setConfigureBucket(bname);
                                }
                            } else {
                                setConfigureBucket(bname);
                            }
                            setUpdateFlag(true);
                            setLoading(false);
                            setBucketModalVisible(false);
                            form.resetFields();
                            setFiltsList([]);
                            fileValidationMsg(false, '', 'errorMessage');
                            notification.success({
                                message: res?.data?.message === "This bucket already exists." ? "This bucket already exists." : 'Bucket configured successfully.'
                            })
                        }
                    })
                    .catch(error => {
                        setLoading(false);
                        if(error?.response?.data?.message){
                            fileValidationMsg(true, `${error?.response?.data?.message}`, 'errorMessage');
                        }
                    })
                } else {
                    setLoading(false);
                    fileValidationMsg(true, `Please upload required file.`, 'errorMessage');
                }
            }
        } catch(error) {
            console.log(error);
        }
    }
    
    const uploadProps = {
        name: 'file',
        action: `${ROOT}/api/upload-file`,
        headers: {
            Authorization: token,
        },
        maxCount: 1,
        beforeUpload(file) {
            try{
                if (file.type !== "application/json") {
                    uploadFileError();
                    return false;
                } else {
                    return true;
                }
            } catch(error) {
                console.log(error);
            }
        },
        onChange(info) {
            try{
                if (info.file.type !== "application/json") {
                    uploadFileError();
                } else {
                    if(info?.fileList?.length){
                        setFiltsList(info.fileList);
                    }
                    if (info.file.status === 'done') {
                        fileValidationMsg(true, `${info.file.name} file uploaded successfully.`, 'successMessage');
                    } else if (info.file.status === 'error') {
                        setFiltsList([]);
                        fileValidationMsg(true, 'Something went wrong. Please try again.', 'errorMessage');
                    }
                }
            } catch(error) {
                console.log(error);
            }
        },
        onRemove() {
            setFiltsList([]);
            fileValidationMsg(false, '', 'errorMessage');
        }
    };

    const uploadFileError = () => {
        setFiltsList([]);
        fileValidationMsg(true, 'Only JSON file format is allowed.', 'errorMessage');
    }

    const fileValidationMsg = (status, message, className) => {
        try{
            setFileError({
                status: status,
                message: message,
                className: className
            });
        } catch(error) {
            console.log(error);
        }
    }

    const removeSpace = (e) => {
        try{
            if(e?.target?.value){
                let fieldVal = e.target.value;
                const fieldId = e.target.id;
                fieldVal = fieldVal.replace(/\s/g,'');
                form.setFieldsValue({
                    [fieldId]: fieldVal
                })
            }
        } catch(error) {
            console.log("removeSpace error: ", error);
        }
    }

    return (
        <Form
            form={form}
            name="addBucketForm"
            className="bucketForm"
            onFinish={onConfigure}
            labelAlign="left"
            initialValues={initialVals}
            {...layout}
        >
            <Form.Item
                label={<>Bucket Name</>}
                name="bucketName"
                tooltip={{ title: 'Please enter bucket name', icon: <QuestionCircleFilled /> }}
                rules={[{
                    validator(_, value) {
                        if(value){
                            value = value.trim();
                            let validRegex = helper.regexValidation(/^[a-z_0-9-]+$/, value);
                            if(validRegex){
                                if(value){
                                    return Promise.resolve()
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            } else {
                                return Promise.reject(new Error(`Bucket names must contain only lowercase letters, numbers, dashes (-), underscores (_). Spaces are not allowed.`))
                            }
                        } else {
                            return Promise.reject(new Error(`Required field.`))
                        }
                    }
                }]}
            >
                <Input id="bucketName" onChange={removeSpace} placeholder="Enter bucket name" />
            </Form.Item>
            <Form.Item
                label={<>Bucket Folder Name (optional)</>}
                name="bucketFolderName"
                tooltip={{ title: 'Please enter bucket folder name', icon: <QuestionCircleFilled /> }}
                rules={[{
                    validator(_, value) {
                        if(value){
                            value = value.trim();
                            let validRegex = helper.regexValidation(/^[a-z_0-9-]+$/, value);
                            if(validRegex){
                                if(value){
                                    return Promise.resolve();
                                }
                            } else {
                                return Promise.reject(new Error(`Bucket folder names must contain only lowercase letters, numbers, dashes (-), underscores (_). Spaces are not allowed.`))
                            }
                        }else {
                            return Promise.resolve();
                        }
                    }
                }]}
            >
                <Input id="bucketFolderName" onChange={removeSpace} placeholder="Enter bucket folder name" />
            </Form.Item>
            {envars?.storageMechanism === 'minio' && (
                <>
                    <Form.Item
                        label={<>Minio Port</>}
                        name="minioPort"
                        tooltip={{ title: 'Please enter minio port e.g 443', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        if(isNaN(value)){
                                            return Promise.reject(new Error(`Please enter only numeric value.`))
                                        } else if(value.indexOf(".") !== -1) {
                                            return Promise.reject(new Error(`Invalid value. Please remove dot.`))
                                        } else if(value.indexOf("-") !== -1) {
                                            return Promise.reject(new Error(`Invalid value. Please remove hyphen.`))
                                        } else if(value.indexOf("+") !== -1) {
                                            return Promise.reject(new Error(`Invalid value. Please remove plus.`))
                                        } else {
                                            return Promise.resolve()
                                        }
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioPort" onChange={removeSpace} placeholder="Enter minio port" />
                    </Form.Item>
                    <Form.Item
                        label={<>Minio End Point URL</>}
                        name="minioEndPoint"
                        tooltip={{ title: 'Please enter minio end point URL', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioEndPoint" onChange={removeSpace} placeholder="Enter end point" />
                    </Form.Item>
                    <Form.Item
                        label={<>Access Key</>}
                        name="minioAccessKey"
                        tooltip={{ title: 'Please enter minio access key', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioAccessKey" onChange={removeSpace} placeholder="Enter minio access key" />
                    </Form.Item>
                    <Form.Item
                        label={<>Secret Key</>}
                        name="minioSecretKey"
                        tooltip={{ title: 'Please enter minio secret key', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioSecretKey" onChange={removeSpace} placeholder="Enter minio secret key" />
                    </Form.Item>
                    <Form.Item
                        label={<>Use SSL</>}
                        name="useSsl"
                        tooltip={{ title: 'Keep it true for https and false for http', icon: <QuestionCircleFilled /> }}
                    >
                        <Switch checkedChildren="True" unCheckedChildren="False" checked={useChecked} onChange={() => setUseChecked(!useChecked)} />
                    </Form.Item>
                </>
            )}
            {envars?.storageMechanism === 'gcp' && (
                <>
                    <Form.Item
                        label={<>Google Application Credentials</>}
                        name="gcpGoogleAppCredentials"
                        tooltip={{ title: 'Please upload JSON file containing google account credentials.', icon: <QuestionCircleFilled /> }}
                    >
                        <Upload id="uploadField" {...uploadProps} fileList={filesList}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                </>
            )}
            {envars?.storageMechanism === 'azure' && (
                <>
                    <Form.Item
                        label={<>Azure Connection String</>}
                        name="azureStorageString"
                        tooltip={{ title: 'Please enter default connection string of Azure.', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="azureStorageString" onChange={removeSpace} placeholder="Enter Azure Connection String" />
                    </Form.Item>
                </>
            )}
            <Form.Item {...tailLayout}>
                {fileError && (
                    <div id="errorDiv" className={fileError.className}>{fileError.message}</div>
                )}
                <Button key="submit" htmlType="submit" type="primary" id="saveBtn" loading={loading}>Save</Button>
                <Button key="cancel" onClick={handleCancel} id="cancelBtn">Cancel</Button>
            </Form.Item>
        </Form>
    )
}

export default ConfigureBucket;