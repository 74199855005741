import React, { useState } from 'react';
import { Button, Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as importHelper from "../ImportQuery/ImportHelper";
import { saveDate, saveQueryStack } from "../../store/actions";
import { getSearcData } from "../../services";
import "./importQuery.scss";
import * as helper from "../helper";
import axiosConfig from '../../axiosConfig';

const ImportFile = (props) => {
    const dispatch = useDispatch();
    const {indexValue, indexAlias, aliasFlag, mappingImageKey} = useSelector(store => store.storeProps);
    const { handleCancel, setImportFile} = props;

    const [fileName, setFileName] = useState("");
    const [disableBtn, setDisableBtn] = useState(true);
    const [isBtnLoading, setIsBtnLoading] = useState(false);
    const [isValid, setIsValid] = useState({
      status: true,
      message: "",
      className: "errorMessage"
    })

    const runQueryHandler = () => {
      try{
        if(fileName){
          setIsBtnLoading(true);
          axiosConfig.get(`/api/get-query?fileName=${fileName}`)
          .then(res => {
            if(res?.data?.query && res?.data?.statusCode === 200){
              const fileString = res.data.query;
              if(fileString.groups && fileString.groups?.length && fileString.date){
                const payload = {
                  body: JSON.stringify({
                    index: indexValue,
                    alias: aliasFlag ? indexAlias : '',
                    groups: fileString.groups,
                    date: fileString.date,
                    chart: false,
                    mappingKey: mappingImageKey
                  })
                }
                getSearcData(payload).then(resp => {
                  setIsBtnLoading(false);
                  if(resp?.data?.data){
                    importHelper.runQuery(fileString).then((parsedQuery) => {
                      if(parsedQuery?.qStack && parsedQuery?.queryDate){
                        dispatch(saveDate(parsedQuery.queryDate));
                        dispatch(saveQueryStack(parsedQuery.qStack))
                        setImportFile(false);
                      }
                    });
                  } else {
                    setDisableBtn(true);
                    setIsValid({
                      status: false,
                      message: "Something went wrong. Please try later.",
                      className: "errorMessage"
                    })
                  }
                })
                .catch(error => {
                  if(error?.response?.data?.message && error?.response?.data?.statusCode !== 401){
                    setIsBtnLoading(false);
                    setDisableBtn(true);
                    setIsValid({
                      status: false,
                      message: error.response.data.message === "Cannot read property 'type' of undefined" ? "Please make sure you have selected the correct elastic index." : "Something went wrong. Please try later.",
                      className: "errorMessage"
                    })
                  }
                })
              } else {
                setIsBtnLoading(false);
                setDisableBtn(true);
                setIsValid({
                  status: false,
                  message: "Something went wrong. Please try later.",
                  className: "errorMessage"
                })
              }
            }
          })
          .catch(error => {
            if(error?.response?.data?.message){
              setIsBtnLoading(false);
              setIsValid({
                status: false,
                message: error.response.data.message,
                className: "errorMessage"
              })
            }
          })
          
        }
      } catch(error) {
        console.log(error);
      }
    }

    const onFileUpload = (e) => {
      try{
        setIsValid({
          status: true,
          message: "",
          className: "errorMessage"
        })
        let file = e.target.files[0];
        if(file && file.name && file.type === "text/plain"){
          let reader = new FileReader();
          reader.onload = function(event) {
            if(event.target.result){
              const fileData = helper.isValidJson(event.target.result);
              if(fileData && fileData.size && fileData.query){
                const data = new FormData();
                data.append('file', file);
                axiosConfig.post('/api/import-query', data)
                .then(res => {
                  if(res?.data?.statusCode === 200){
                    setFileName(file.name);
                    setDisableBtn(false);
                    setIsValid({
                      status: false,
                      message: res?.data?.message ? res.data.message : '',
                      className: "successMessage"
                    })
                  }
                })
                .catch(error => {
                  setDisableBtn(true);
                  if(error?.response?.data?.message){
                    setIsValid({
                      status: false,
                      message: error.response.data.message,
                      className: "errorMessage"
                    })
                  }
                })
              } else {
                setIsValid({
                  status: false,
                  message: "File seems to be not correct.",
                  className: "errorMessage"
                })
              }
            }
          };
          reader.readAsText(file);
        } else {
          setDisableBtn(true);
          setIsValid({
            status: false,
            message: "Please upload text file only.",
            className: "errorMessage"
          })
        }
      } catch(error) {
        setDisableBtn(true);
        console.log("File upload error: ", error);
      }
    }

    return (
      <div className="add-filters-section">
        <Row className="ImportFileRow">
          <Col span={24} className="importFileCol">
            <h3>Import Query</h3>
          </Col>
          <Col span={12} className="importFileCol">
            <label>Please upload text file only</label>
            <input id="fileInput" type="file" onChange={onFileUpload}/>
            {!isValid.status && (
              <div id="infoDiv" className={isValid.className}>{isValid.message}</div>
            )}
            <div className="importFooter">
              <Button id="runQueryBtn" disabled={disableBtn} loading={isBtnLoading} type="primary" onClick={runQueryHandler}>Run Query</Button>
              <Button onClick={() => handleCancel()}>Cancel</Button>
            </div>
          </Col>
        </Row> 
      </div>
    )
}

export default ImportFile;