import { Col, Row, Table, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import ROOT from "../../client";
import './pdd.scss';
import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import Loader from '../Loader';
import moment from 'moment-timezone';
import { getMinioClient } from '../../services';
import axiosConfig from '../../axiosConfig';

const PDD = () => {
    const bodyEl = document.getElementById('bodyEl');
    if(bodyEl){
        bodyEl.classList.remove('dashboardBg');
        bodyEl.classList.remove('loginBg');
    }
    const { indexValue, filters, observedAtDetail, mappingImageKey, searchData, pddDetail, selectedBucket, envars, buckets, uuids } = useSelector((state) => state.storeProps);
    const [dataSource, setDataSource] = useState([]);
    const [groups, setGroups] = useState([]);
    const [applicationsArr, setApplicationsArr] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const token = uuids?.uuid1;
    const [date, setDate] = useState('');

    const processPddData = (responseUrls) => {
        if(responseUrls?.length){
            const data = searchData;
            let groupsArr = [];
            let applications = [];
            const dataArr = [];
            data.forEach((item, i) => {
                const source = item._source ? item._source : item;
                groupsArr.push(source.group);
                if(source.application){
                    applications.push(source.application);
                } else if(source.application_name){
                    applications.push(source.application_name);
                }
            })
            
            if(groupsArr.length){
                groupsArr = [...new Set(groupsArr)];
                setGroups(groupsArr);
                groupsArr.forEach((groupName, index) => {
                    data.forEach((item, i) => {
                        const source = item._source ? item._source : item;
                        if(source.group === groupName){
                            const dataObj = {
                                key: Math.random(),
                                step: i === 0 ? `${index + 1}.0` : '',
                                subStep: i > 0 ? `${index + 1}.${i}` : '',
                                group: groupName,
                                description: source.description,
                                screenshot: responseUrls[i] && responseUrls[i].url ? responseUrls[i].url : '',
                                coord_x: source.coord_x,
                                coord_y: source.coord_y,
                                boundingBox: source.bounding_box
                            }
                            dataArr.push(dataObj);
                        }
                    })
                })
                if(dataArr.length){
                    setDataSource(dataArr);
                }
            }
            if(applications.length){
                applications = [...new Set(applications)];
                setApplicationsArr(applications);
            }
            setTimeout(() => {
                setIsLoading(false);
            }, 2000)
        } else {
            notification.error({
                message: "Something went wrong. Please try again."
            })
            setIsLoading(false);
        }
    }

    const generateSignedUrl = (mappingKeys, minioConfig) => {
        const mc = getMinioClient({
            endPoint: minioConfig?.endpoint ? minioConfig.endpoint : minioConfig?.minioEndPoint ? minioConfig.minioEndPoint : "storage-fiq-53e0863f-82db-4403.fiq-dev.com",
            port: minioConfig?.PORT ? Number(minioConfig.PORT) : minioConfig?.minioPort ? Number(minioConfig.minioPort) : 443,
            useSSL: minioConfig?.USE_SSL ? minioConfig.USE_SSL : minioConfig?.useSsl ? minioConfig.useSsl : true,
            accessKey: minioConfig?.access_key_id ? minioConfig.access_key_id : minioConfig?.minioAccessKey ? minioConfig.minioAccessKey : "fortressiq",
            secretKey: minioConfig?.secret_access_key ? minioConfig.secret_access_key : minioConfig?.minioSecretKey ? minioConfig.minioSecretKey : "mn2smDXjJW9cWbrpG5"
        })
        let promises = mappingKeys.map((imageName) => {
            return mc.presignedGetObject(selectedBucket.bucketName, imageName)
            .then(resp => {
                return {
                    screenshotKey: imageName,
                    url: resp,
                    error: false
                }
            })
            .catch(error => {
                return {
                    screenshotKey: imageName,
                    url: null,
                    error: true 
                }
            });
        })
        Promise.all(promises)
        .then(results => {
            if(results){
                const existError = results.find(s => s.error === true);
                if(existError){
                    notification.error({
                        message: "Please check your first bucket configuration."
                    })
                }
                processPddData(results);
            }
        })
        .catch(e => {
            console.error(e);
        })
    }
    
    useEffect(() => {
        try {
            if(token) {
                if(searchData?.length && pddDetail?.status){
                    const currentDate = moment(Date.now()).format('MMMM DD, YYYY');
                    setDate(currentDate ? currentDate : '');
                    setIsLoading(true);
                    const data = searchData;
                    const apiPath = `${ROOT}/api/image_carousel`;
                    let mappingKeys = data.map(obj => {
                        const source = obj._source ? obj._source : obj;
                        return source[mappingImageKey] ? source[mappingImageKey] : null;
                    });
                    const payLoad = {
                        originalImagesDetails: {
                            screenshotKeys: mappingKeys,
                            bucketName: selectedBucket?.bucketName ? selectedBucket.bucketName : '',
                            metaInfo: selectedBucket?.metaInfo ? selectedBucket.metaInfo : '',
                            bucketFolderName: selectedBucket?.bucketFolderName ? selectedBucket.bucketFolderName : ''
                        },
                        maskedImagesDetails: null
                    };
                    if(envars?.storageMechanism === "minio"){
                        if(mappingKeys.length){
                            const bucketInfo = buckets.find(item => item.bucketName === selectedBucket.bucketName);
                            if(bucketInfo){
                                if(envars?.bucketConfigAccess){
                                    axiosConfig.get(`${ROOT}/api/bucket-data?metaInfo=${bucketInfo.metaInfo}&secrets=true`)
                                    .then(res => {
                                        if(res?.data?.secrets){
                                            const secretsObj = JSON.parse(Buffer.from(res.data.secrets, 'base64').toString('ascii'));
                                            generateSignedUrl(mappingKeys, secretsObj);
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    })
                                } else {
                                    generateSignedUrl(mappingKeys, envars);
                                }
                            }
                        }
                    } else {
                        axiosConfig.post(apiPath, payLoad)
                        .then(response => {
                            if(response.data){
                                const responseUrls = response.data.originalImagesUrls;
                                processPddData(responseUrls);
                            }
                        })
                        .catch(error => {
                            if(error?.response?.data?.message){
                                notification.error({
                                    message: error.response.data.message
                                })
                            }
                        }); 
                    }
                } else {
                    notification.error({
                        message: 'Please add required observer filter to generate PDD.',
                        duration: 10
                    })
                }
            }
        } catch(error) {
            console.log(error);
            setIsLoading(false);
        }
        // eslint-disable-next-line
    }, [indexValue, filters, observedAtDetail])
      
    const columns = [
        {
          title: 'Step',
          dataIndex: 'step',
          key: 'Step',
        },
        {
          title: 'Substep',
          dataIndex: 'subStep',
          key: 'Substep',
        },
        {
          title: 'Group',
          dataIndex: 'group',
          key: 'Group',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'Description',
        },
        {
          title: 'Screenshot',
          dataIndex: 'screenshot',
          key: 'Screenshot',
          width: '20%',
          render: (text, record) => {
            try{
                if(record && record.boundingBox && record.coord_x !== null && record.coord_x !== undefined && record.coord_y !== null && record.coord_y !== undefined && pddDetail?.status){
                    const bBox = JSON.parse(record.boundingBox);
                    const width = bBox[2] ? bBox[2] : 0;
                    const height = bBox[3] ? bBox[3] : 0;
                    const coordX = record.screenshot ? record.coord_x : 0;
                    const coordY = record.screenshot ? record.coord_y : 0;
                    const left = coordX > 200 ? coordX - 15 : coordX;
                    const top = coordY > 100 ? coordY - 15 : coordY;
                    return (
                        <div className="imageCell">
                            <div 
                                className="imageContainer" 
                                style={{
                                    width: `${width}px`, 
                                    height: `${height}px`, 
                                    marginTop: `${record.screenshot ? record.coord_y > 100 ? `-${record.coord_y - 100}px` : `0px` : `0px`}`, 
                                    marginLeft: `${record.screenshot ? record.coord_x > 200 ? `-${record.coord_x - 200}px` : `0px` : `0px`}`,
                                    background: `url("${text ? text : './no-image-found.png'}") 0 0 no-repeat`
                                }}
                            >
                                {record.screenshot && (
                                    <>
                                        <div className="circle" style={{top: `${top}px`, left: `${left}px`}}></div>
                                        <div 
                                            className="bbox" 
                                            style={{
                                                top: `${bBox[0] && bBox[0] > 0 ? bBox[0] : 0}px`, 
                                                left: `${bBox[1] && bBox[1] > 0 ? bBox[1] : 0}px`,
                                                width: `${width}px`,
                                                height: `${height}px`
                                            }}
                                        >
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    )
                }
              } catch(error){
                  console.log("PDD image error: ", error);
              }
            }
          }
    ];
    const exportPdf = () => {
        window.print();
    }

    return (
        <>
         {!token ?
            (<Redirect to="/login" />) : (
                <>
                 {isLoading && (
                    <Loader />
                )}
                <button className="exportPddButton" onClick={exportPdf} type="button">Export <img alt="Export" src="./pdf-icon.png"/></button>
                <div className="headerPdd">
                    <div className="headerContent">
                        <div className="logo"><img alt="Fortress IQ" src="./logo-fortress.png"/></div>
                        <Row align="middle" className="bannerInfo">
                            <Col span={24}>
                                <h1>Robotic Process Automation</h1>
                                <h2>Process Definition Document</h2>
                                <ul className="docInfo d-flex">
                                    <li>Date: {date}</li>
                                    <li>Version: 1.0</li>
                                </ul>
                            </Col>
                        </Row>
                        <div className="scrollSection">
                            <p><img alt="mouse" src="./icon-scroll.png"/> <span className="scrollText">Scroll to Explore</span></p>
                            <span className="verticalLine"></span>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <div className="pageContainer docContent">
                        <Row>
                            <Col span={8}>
                                <div className="pageHeading">
                                    <h2>1.</h2>
                                    <h2>Contents</h2>
                                    <span className="underline"></span>
                                </div>
                            </Col>
                            <Col span={16}>
                                <div className="pageContent">
                                    <div className="docTree">
                                        <div className="docTreeItem">
                                            <p><span className="counter">1</span> Contents</p>
                                        </div>
                                        <div className="docTreeItem">
                                            <p><span className="counter">2</span> Document Objective</p>
                                        </div>
                                        <div className="docTreeItem">
                                            <p><span className="counter">3</span> Overview</p>
                                            <p className="subItem"><span className="counter">3.1</span>	High Level Process Map</p>
                                            <p className="subItem"><span className="counter">3.2</span> Impacted Systems</p>
                                        </div>
                                        <div className="docTreeItem">
                                            <p><span className="counter">4</span> Process Details</p>
                                            <p className="subItem"><span className="counter">4.1</span> Process Steps</p>
                                            <p className="subItem"><span className="counter">4.2</span>	Process Maps</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="content objective">
                    <div className="pageContainer">
                        <Row>
                            <Col span={8}>
                                <div className="pageHeading">
                                    <h2>2.</h2>
                                    <h2>Document Objective</h2>
                                    <span className="underline"></span>
                                </div>
                            </Col>
                            <Col span={16}>
                                <div className="pageContent">
                                    <p>The Process Definition Document (PDD) should be used to capture the step-by-step manual activities currently performed on a process that is being considered for Robotic Process Automation (RPA).</p>
                                    <p className="textBold">The document should contain the following:</p>
                                    <ul>
                                    <li>High-level illustrative overview of the process intended for RPA.</li>
                                    <li>Detailed description of the end-to-end process using various cross functional flow charts, screen shots, and bulleted steps.</li>
                                    <li>Business rules and processes that will govern the technical development of the automated process.</li>
                                    <li>Target applications / technologies that the automated process will interact with.</li>
                                    <li>Adequate information to enable the developer to compose an initial Solution Design Document.</li>
                                    </ul>
                                    <p>The document will become reference material for the operational support team following successful implementation.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="content">
                    <div className="pageContainer">
                        <Row>
                            <Col span={24}>
                                <div className="pageHeading">
                                    <h2>3.</h2>
                                    <h2>Overview</h2>
                                    <span className="underline"></span>
                                    </div>
                                    <div className="pageSubHeading">
                                    <h2>3.1 High Level Process Map</h2>
                                </div>
                                <div className="pageContent">
                                    <Row className="processMap">
                                        {groups.length > 0 && (
                                            groups.map((groupName) => {
                                                return (
                                                    <Col key={groupName} span={4} className="processCol">
                                                        <div className="processItem">{groupName}</div>
                                                    </Col>
                                                )
                                            })
                                        )}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="content">
                    <div className="pageContainer">
                        <Row>
                            <Col span={24}>
                                <div className="pageSubHeading">
                                    <h2>3.2 Impacted Systems</h2>
                                </div>
                                <div className="pageContent">
                                    {applicationsArr.length > 0 ? (
                                        <ul className="applications">
                                            {
                                                applicationsArr.map((applicationName) => {
                                                    return (
                                                        <li key={applicationName}>{applicationName}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    ): (
                                        <p className="notFound">No application name found.</p>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* <div className="content">
                    <div className="pageContainer">
                        <Row>
                            <Col span={24}>
                                <div className="pageHeading">
                                    <h2>4.</h2>
                                    <h2>Process Details</h2>
                                    <span className="underline"></span>
                                </div>
                                <div className="pageSubHeading">
                                    <h2>4.1 Process Maps</h2>
                                </div>
                                <div className="pageContent">
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div> */}
                <div className="content">
                <div className="pageContainer">
                    <Row>
                        <Col span={24}>
                            <div className="pageHeading">
                                <h2>4.</h2>
                                <h2>Process Details</h2>
                                <span className="underline"></span>
                            </div>
                            <div className="pageSubHeading">
                                <h2>4.1 Process Steps</h2>
                            </div>
                            <div className="pageContent">
                                <Table dataSource={dataSource} columns={columns} bordered pagination={false}/>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            </>
            )
            }
        </>
    )
}
export default PDD;