import { Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import ConfigureBucket from "./configureBucket";
import UpdateBucket from "./updateBucket";
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

const AddBucket = (props) => {
    const { envars, uuids } = useSelector(store => store.storeProps);
    const token =  uuids?.uuid1;
    const { bucketModalVisible, setBucketModalVisible, bucketsOption, setUpdateFlag, setConfigureBucket } = props;
    const [initialVals, setInitialVals] = useState({
        useSsl: true
    })
    useEffect(() => {
        if(envars.storageMechanism === "minio"){
            setInitialVals({
                ...initialVals,
                bucketName: envars.bucketName && envars.bucketName !== "undefined" ? envars.bucketName : "",
                minioAccessKey: envars.minioAccessKey && envars.minioAccessKey !== "undefined" ? envars.minioAccessKey : "",
                minioEndPoint: envars.minioEndPoint && envars.minioEndPoint !== "undefined" ? envars.minioEndPoint : "s3.amazonaws.com",
                minioPort: envars.minioPort && envars.minioPort !== "undefined" ? envars.minioPort : 443,
                minioSecretKey: envars.minioSecretKey && envars.minioSecretKey !== "undefined" ? envars.minioSecretKey : "",
                useSsl: envars.minioAccessKey && envars.minioAccessKey !== "undefined" ? envars.useSsl : true
            })

        }
        // eslint-disable-next-line
    }, [envars])
    const handleCancel = () => {
        setBucketModalVisible(false);
    };
    return (
        <>
        {!token ?
            (
                <Redirect to="/login" />
            ) : (
                <Modal 
                    title="Configure Bucket" 
                    visible={bucketModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    className="bucketModal"
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Configure bucket" key="1">
                            <ConfigureBucket setBucketModalVisible={setBucketModalVisible} setUpdateFlag={setUpdateFlag} initialVals={initialVals} setConfigureBucket={setConfigureBucket}/>
                        </TabPane>
                        <TabPane tab="Update existing bucket" key="2">
                            <UpdateBucket setBucketModalVisible={setBucketModalVisible} bucketsOption={bucketsOption} setUpdateFlag={setUpdateFlag} setConfigureBucket={setConfigureBucket} initialVals={initialVals}/>
                        </TabPane>
                    </Tabs>
                </Modal>
            )
        }
            
        </>
    )
}

export default AddBucket;