import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './App.scss';
import Dashboard from './components/Dashboard';
import LogIn from './components/LogIn';
import EnvarsConfig from './components/EnvarsConfiguration/envarsConfig';
import PDD from './components/PDD';
import { Provider } from 'react-redux';
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";
import PageNotFound from './components/PageNotFound';
import ShowIndices from './components/Indices';

function App() {
  console.warn = () => {}
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={LogIn} />
            <Route path="/indices" component={ShowIndices} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/pdd" component={PDD} />
            <Route exact path="/" component={EnvarsConfig} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;