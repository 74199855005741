import { ADD_INDEX_VALUE, SAVE_SEARCH_DATA, SAVE_IMG_URL, SAVE_FILTERS, SAVE_DATE, SAVE_ALL_DATA_IDS, SAVE_SCREEN_SIGNATURE, SAVE_IMAGE_LOAD_COUNT, SAVE_MAPPING_KEY, UPDATE_CONFIGURE_FIELDS, SAVE_AREA_QUERY, SAVE_CONFIGURE_FIELD_STATUS, UPDATE_ENVARS, SAVE_GCP_FILENAME, SAVE_PDD_DETAIL, SAVE_BUCKETS, SAVE_SELECTED_BUCKET, SAVE_ENVARS, SAVE_SLOTS, SAVE_IMAGE_KEYS, SAVE_FILE_CONFIGURATIONS, SAVE_IMAGE_DETAIL, SAVE_MASKED_BUCKET, SET_VIEW, SAVE_MAPPING_PROPS, SAVE_QUERY_STACK, SAVE_GRAPH_VISIBILITY, SAVE_MINIO_CLIENT, SET_ENABLE_SEARCH, SAVE_MASKED_FLAG, SAVE_UUIDS, SAVE_NAME, SAVE_ROUTE_LOCATION, SAVE_SESSION_FLAG } from "../types";

export const saveIndexValue = (value) => {
    return {
        type: ADD_INDEX_VALUE,
        payload: {
            indexValue: value.index,
            allIndexes: value.allIndexes,
            indexAlias: value.alias,
            aliasFlag: value.aliasFlag,
            aliasIndexesArr: value.aliasIndexesArr
        }
    }
}

export const saveMappingKey = (value) => {
    return {
        type: SAVE_MAPPING_KEY,
        payload: {
            mappingImageKey: value
        }
    }
}

export const saveSearchData = (data) => {
    return {
        type: SAVE_SEARCH_DATA,
        payload: {
            searchData: data
        }
    }
}

export const saveImgUrl = (data) => {
    return {
        type: SAVE_IMG_URL,
        payload: {
            imagesUrls: data
        }
    }
}

export const saveFilters = (data) => {
    return {
        type: SAVE_FILTERS,
        payload: {
            filters: data
        }
    }
}

export const saveDate = (data) => {
    return {
        type: SAVE_DATE,
        payload: {
            observedAtDetail: data
        }
    }
}

export const saveImageLoadCount = (data) => {
    return {
        type: SAVE_IMAGE_LOAD_COUNT,
        payload: {
            imageLoadCount: data
        }
    }
}

export const saveAllDataIds = (data) => {
    return {
        type: SAVE_ALL_DATA_IDS,
        payload: {
            allDataIds: data
        }
    }
}

export const saveScreenSignature = (data) => {
    return {
        type: SAVE_SCREEN_SIGNATURE,
        payload: {
            screenSignature: data
        }
    }
}

export const saveAreaQuery = (data) => {
    return {
        type: SAVE_AREA_QUERY,
        payload: {
            areaQuery: data
        }
    }
}

export const configureFieldStatus = (value) => {
    return {
        type: SAVE_CONFIGURE_FIELD_STATUS,
        payload: {
            isConfigureFieldEnable: value
        }
    }
}

export const updateConfigureFields = (data) => {
    return {
        type: UPDATE_CONFIGURE_FIELDS,
        payload: {
            configuredFields: data
        }
    }
}

export const updateEnvarsStatus = (value) => {
    return {
        type: UPDATE_ENVARS,
        payload: {
            updateEnvars: value
        }
    }
}

export const saveGcpFileName = (value) => {
    return {
        type: SAVE_GCP_FILENAME,
        payload: {
            gcpFileName: value
        }
    }
}

export const savePddDetail = (data) => {
    return {
        type: SAVE_PDD_DETAIL,
        payload: {
            pddDetail: data
        }
    }
}

export const saveBuckets = (data) => {
    return {
        type: SAVE_BUCKETS,
        payload: {
            buckets: data
        }
    }
}

export const saveSelectedBucket = (data) => {
    return {
        type: SAVE_SELECTED_BUCKET,
        payload: {
            selectedBucket: data
        }
    }
}

export const saveEnvars = (data) => {
    return {
        type: SAVE_ENVARS,
        payload: {
            envars: data
        }
    }
}

export const saveCarouselSlots = (data) => {
    return {
        type: SAVE_SLOTS,
        payload: {
            carouselSlots: data
        }
    }
}

export const saveImageKeys = (data) => {
    return {
        type: SAVE_IMAGE_KEYS,
        payload: {
            imageKeys: data
        }
    }
}

export const saveFileConfigurations = (data) => {
    return {
        type: SAVE_FILE_CONFIGURATIONS,
        payload: {
            fileConfigurations: data
        }
    }
}

export const saveImageDetail = (data) => {
    return {
        type: SAVE_IMAGE_DETAIL,
        payload: {
            imageDetail: data
        }
    }
}

export const saveMaskedBucket = (data) => {
    return {
        type: SAVE_MASKED_BUCKET,
        payload: {
            maskedBucket: data
        }
    }
}

export const setView = (data) => {
    return {
        type: SET_VIEW,
        payload: data
    }
}

export const saveMappingProps = (data) => {
    return {
        type: SAVE_MAPPING_PROPS,
        payload: {
            mappingProps: data
        }
    }
}

export const saveQueryStack = (data) => {
    return {
        type: SAVE_QUERY_STACK,
        payload: {
            queryStack: data
        }
    }
}

export const saveGraphVisibility = (data) => {
    return {
        type: SAVE_GRAPH_VISIBILITY,
        payload: {
            graphVisibility: data
        }
    }
}

export const saveMinioClient = (data) => {
    return {
        type: SAVE_MINIO_CLIENT,
        payload: {
            minioClient: data
        }
    }
}

export const setEnableSearch = (data) => {
    return {
        type: SET_ENABLE_SEARCH,
        payload: {
            enableSearch: data
        }
    }
}

export const saveMaskedFlag = (data) => {
    return {
        type: SAVE_MASKED_FLAG,
        payload: {
            maskedFlag: data
        }
    }
}

export const saveUuids = (data) => {
    return {
        type: SAVE_UUIDS,
        payload: {
            uuids: data
        }
    }
}

export const saveName = (data) => {
    return {
        type: SAVE_NAME,
        payload: {
            username: data
        }
    }
}

export const saveRouteLocation = (data) => {
    return {
        type: SAVE_ROUTE_LOCATION,
        payload: {
            routeLocation: data
        }
    }
}

export const saveSessionFlag = (data) => {
    return {
        type: SAVE_SESSION_FLAG,
        payload: {
            sessionFlag: data
        }
    }
}
