import React, { useEffect, useState, useRef } from 'react';
import { Button, Modal, notification } from 'antd';
import ROOT from '../../client';
import { Chart, Settings, Axis, BarSeries } from '@elastic/charts';
import { CirclePicker } from 'react-color';
import "./carousel.scss";
import { Carousel as CarouselSlider} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { saveAllDataIds, saveSearchData, saveAreaQuery, saveCarouselSlots, saveImageKeys } from "../../store/actions";
import { flatten } from 'lodash';
import RenderImage from './RenderImage';
import axiosConfig from '../../axiosConfig';
import axios from 'axios';

const Box = ({ children, geometry, style }) => (
    <div
      style={{
        ...style,
        position: 'absolute',
        left: `${geometry.x}%`,
        top: `${geometry.y}%`,
        height: `${geometry.height}%`,
        width: `${geometry.width}%`,
      }}
      key={`${geometry.height}${geometry.x}`}
      id={`${geometry.height}${geometry.x}`}
    >
      {children}
    </div>
);

let cancelToken

const Carousel = (props) => {
    const dispatch = useDispatch();
    const {aliasFlag, indexAlias, indexValue, filters, mappingImageKey, imageLoadCount, observedAtDetail, isConfigureFieldEnable, selectedBucket, carouselSlots, imageKeys, maskedBucket, view, envars, minioClient} = useSelector(store => store.storeProps);
    // eslint-disable-next-line
    const {queryData, setQueryData, selectedOption, setAnnotations, annotations, setIsLoading, setGraphdata, setIsDataLoading } = props;
    const [toggleCarousel, setToggleCarousel] = useState(false);
    const [annotation, setAnnotation] = useState({});
    const [imgIndex, setImgIndex] = useState(0);
    const [carouselData, setCarouselData] = useState([]);
    const [lastIndex, setLastIndex] = useState(0);
    const [visible, setVisible] = useState(false);
    const [colorModalVisible, setColorModalVisible] = useState(false);
    const [graphModalVisible, setGraphModalVisible] = useState(false);
    const [coord, setCoord] = useState({});
    const [currentSlot, setcurrentSlot] = useState(0);
    const [initialCall, setinitialCall] = useState(false);
    const initialLoadCount = 5;
    const carouselSize = 100;
    const [selectedSlide, setselectedSlide] = useState(0);

    useEffect(() => {
        window.addEventListener("keydown", clickHandler);
        return () => window.removeEventListener("keydown", clickHandler);
    });

    // call fetch image whenever user searches query
    useEffect(()=>{
        let unmount = false;
        try{
            if(!unmount){
                fetchImage(0, initialLoadCount);
                setToggleCarousel(!toggleCarousel);
                dispatch(saveCarouselSlots(["1"]));
            }
        } catch(error) {
            console.log(error);
        }
        return () => {
            unmount = true;
        }
        // eslint-disable-next-line
    }, [queryData]);


    useEffect(()=>{
        try{
            setAnnotations([]);
            (view === 'bounding-box') && fetchAnnoations(imgIndex);
        } catch(error) {
            console.log(error);
        }
        // eslint-disable-next-line
    }, [view]);
    
    useEffect(()=>{
        if(!initialCall){
            handleImageLoad(0,'bulletDown');
        }
        // eslint-disable-next-line
    }, [currentSlot]);
    

    const splitArrIntoChunks = (array) => {
        let chunkSize = carouselSize;
        let i, j, accum = [];
      
        for(i=0, j=array.length; i<j; i+=chunkSize) {
            accum = [...accum, array.slice(i, i+chunkSize)];
        }
      
        return accum;
    }

    const [isPending, setIsPending] = useState(true);

    // fetching images from server
    const fetchImage = async (index, imgCount) => {
        try {
            if(queryData.length && selectedBucket){
                const endIndex = queryData.length >= index + Number(imgCount) ? index + Number(imgCount) : queryData.length;
                setLastIndex(endIndex);
                const apiPath = `${ROOT}/api/image_carousel`;
                let mappingKeys = queryData.map(data => data[mappingImageKey]);
                const slicedKeys = mappingKeys.slice(index, endIndex);
                if(slicedKeys?.length){
                    setIsPending(true);
                    setIsDataLoading(true);
                    const payLoad = {
                        originalImagesDetails: {
                            screenshotKeys: slicedKeys,
                            bucketName: selectedBucket?.bucketName ? selectedBucket.bucketName : '',
                            metaInfo: selectedBucket?.metaInfo ? selectedBucket.metaInfo : '',
                            bucketFolderName: selectedBucket?.bucketFolderName ? selectedBucket.bucketFolderName : ''
                        },
                        maskedImagesDetails: maskedBucket ? {
                            screenshotKeys: slicedKeys,
                            bucketName: maskedBucket?.bucketName ? maskedBucket.bucketName : '',
                            metaInfo: maskedBucket?.metaInfo ? maskedBucket.metaInfo : '',
                            bucketFolderName: selectedBucket?.bucketFolderName ? selectedBucket.bucketFolderName : ''
                        } : null
                    };
                    if(envars?.storageMechanism === "minio" && minioClient){
                        let promises = slicedKeys.map((imageName) => {
                            return minioClient.firstBucket.presignedGetObject(selectedBucket.bucketName, imageName)
                            .then(res => {
                                return {
                                    screenshotKey: imageName,
                                    url: res,
                                    error: false
                                }
                            })
                            .catch(error => {
                                return {
                                    screenshotKey: imageName,
                                    url: null,
                                    error: true 
                                }
                            });
                        })
                        Promise.all(promises)
                        .then(results => {
                            if(results){
                                const allUrls = {
                                    originalImagesUrls: results
                                }
                                if(maskedBucket){
                                    let maskPromises = slicedKeys.map((imageName) => {
                                        return minioClient.secondBucket.presignedGetObject(maskedBucket.bucketName, imageName)
                                        .then(res => {
                                            return {
                                                screenshotKey: imageName,
                                                url: res,
                                                error: false
                                            }
                                        })
                                        .catch(error => {
                                            return {
                                                screenshotKey: imageName,
                                                url: null,
                                                error: true 
                                            }
                                        });
                                    })
                                    Promise.all(maskPromises)
                                    .then(maskResults => {
                                        if(maskResults){
                                            const existErrorB2 = maskResults.find(s => s.error === true);
                                            if(existErrorB2){
                                                notification.error({
                                                    message: "Please check your second bucket configuration."
                                                })
                                            }
                                            const existErrorB1 = results.find(s => s.error === true);
                                            if(existErrorB1){
                                                notification.error({
                                                    message: "Please check your first bucket configuration."
                                                })
                                            }
                                            allUrls.maskedImagesUrls = maskResults;
                                            getImages(allUrls, index, endIndex)
                                        }
                                    })
                                    .catch(e => {
                                        console.log("promise catch: ", e)
                                        console.error(e);
                                        setIsDataLoading(false);
                                    })
                                } else {
                                    const existError = results.find(s => s.error === true);
                                    if(existError){
                                        notification.error({
                                            message: "Please check your first bucket configuration."
                                        })
                                    }
                                    getImages(allUrls, index, endIndex)
                                }
                            }
                        })
                        .catch(e => {
                            setIsDataLoading(false);
                            console.error(e);
                        })
                    } else {
                        await axiosConfig.post(apiPath, payLoad).then(response => {
                            if(response.data){
                                getImages(response.data, index, endIndex);
                            }
                        })
                        .catch(error => {
                            setIsDataLoading(false);
                            if(error?.response?.data?.message){
                                notification.error({
                                    message: error.response.data.message
                                })
                            }
                        }); 
                    }
                } else {
                    setIsDataLoading(false);
                    setIsPending(false);
                }
                if(index === 0){
                    setselectedSlide(0)
                    setImgIndex(0);
                    (view === 'bounding-box') && fetchAnnoations(0);
                }
            } else {
                setIsLoading(false);
                setIsDataLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            notification.error({
                message: "Something went wrong. Please try later."
            });
            console.log(error);
        }
    }

    const getImages = (responseUrls, index, endIndex) => {
        try{
            if(responseUrls){
                let updatedUrls = [];
                responseUrls.originalImagesUrls.forEach((values, idx) => {
                    const url =  values && values.url && values.url !== null ? values.url : './no-image.png';
                    const screenshotKey = values && values.screenshotKey && values.screenshotKey !== null ? values.screenshotKey : '';
                    let maskedUrl = ''
                    let maskedScreenshotKey = '';

                    if(responseUrls?.maskedImagesUrls?.[idx]){
                        const maskedVal = responseUrls.maskedImagesUrls[idx];
                        maskedUrl = maskedVal && maskedVal.url && maskedVal.url !== null ? maskedVal.url : './no-image.png';
                        maskedScreenshotKey = maskedVal && maskedVal.screenshotKey && maskedVal.screenshotKey !== null ? maskedVal.screenshotKey : '';
                    }
                    
                    updatedUrls.push({
                        url,
                        screenshotKey,
                        maskedUrl, 
                        maskedScreenshotKey
                    })
                });
                let carouselAlldata = [...carouselData];
                let allKeys = imageKeys?.length ? [...imageKeys] : [];
                allKeys = flatten(allKeys);
                carouselAlldata = flatten(carouselAlldata);
                for(let i = index, j = 0;
                    (i < endIndex || j < updatedUrls.length);
                    i++, j++){
                    carouselAlldata.push({index: i, data: queryData[i], url: updatedUrls[j]?.url, maskedUrl: updatedUrls[j]?.maskedUrl});
                    if(updatedUrls[j]?.screenshotKey){
                        allKeys.push(updatedUrls[j]?.screenshotKey);
                    }
                }
                const allKeysChunks = splitArrIntoChunks(allKeys);
                dispatch(saveImageKeys(allKeysChunks));
                setCarouselData(splitArrIntoChunks(carouselAlldata));
            }
            setTimeout(() => {
                setIsLoading(false);
                setIsDataLoading(false);
            }, 3000)
        } catch(error) {
            console.log(error);
        }
    }
 
    const loadNextSlot = () => {
        try{
            if(isPending){
                if(carouselSlots?.length){
                    const newSlot = currentSlot + 2;
                    const savedSlots = [...carouselSlots];
                    const isExist = savedSlots.includes(newSlot);
                    if(!isExist){
                        savedSlots.push(newSlot);
                        dispatch(saveCarouselSlots(savedSlots))
                    }
                }
                setcurrentSlot(currentSlot + 1);
                setToggleCarousel(!toggleCarousel);
            }
        } catch(error) {
            console.log(error);
        }
    }

    const loadPreviousSlot = () => {
        if(!isPending){
            setIsPending(true);
        }
        setcurrentSlot(currentSlot - 1);
        setToggleCarousel(!toggleCarousel);
    }

    const handleImageLoad = (event, eventName) => {
        let imgnewIndex = (carouselSize * currentSlot) + imgIndex;
        let newEvent = (carouselSize * currentSlot) + event;
        if(lastIndex === initialLoadCount){
            fetchImage(lastIndex, imageLoadCount);
        } else {
            if(eventName==="next" && lastIndex === imgnewIndex + Number(imageLoadCount)){
                fetchImage(lastIndex, imageLoadCount);
            }
            if((eventName==="bulletDown" || eventName==="bulletUp") && lastIndex === newEvent + 1){
                fetchImage(lastIndex, imageLoadCount);
            }
        }
        setImgIndex(event);
        setTimeout(() => {
            fetchAnnoations(event);
        }, 0);
    }
    
    const handleChange = (event) =>{
        try{
            setinitialCall(false);
            let eventName  = '';
            if(imgIndex < event){
                eventName =  imgIndex + 1 === event ? 'next' : 'bulletDown';
            } else {
                eventName =  imgIndex - 1 === event ? 'prev' : 'bulletUp';
            }
            if(imgIndex === 99 && eventName !== 'prev'){
                setselectedSlide(0);
            }
            if(event >= carouselSize - 1 && imgIndex === 99){
                loadNextSlot();
            } else if(event <= 0 && imgIndex === 0 && currentSlot !== 0 && eventName !== 'next' && eventName !== 'bulletDown'){
                loadPreviousSlot();
                setselectedSlide(99);
            } else if(lastIndex !== imgIndex + 1) {
                handleImageLoad(event, eventName);
            } else {
                setImgIndex(event);
                if(event  + 2 === lastIndex && view === 'bounding-box')
                {
                    fetchAnnoations(event);
                }
                setIsDataLoading(false);
            }

        } catch(error) {
            console.log(error);
        }
    }

    const saveBoundingBox = async (index,boundArray, annotationsArr) =>{
        try{
            const url = `${ROOT}/api/add/boundingbox`;
            const payLoad = {
                screenshot_key: carouselData[currentSlot][index] && carouselData[currentSlot][index].data[mappingImageKey] ? carouselData[currentSlot][index].data[mappingImageKey] : '',
                boundingbox_details: boundArray
                };
            await axiosConfig.post(url, payLoad)
            .then(response => {
                if(response.data.statusCode === 200){
                    notification.success({
                        message: "Bounding box saved successfully."
                    })
                }
            })
            .catch(err => {
                if(annotationsArr.length && annotationsArr[index] && annotationsArr[index].length){
                    const newAnnotations = [...annotationsArr]
                    newAnnotations[index].pop()
                    setAnnotations(newAnnotations);
                }
                const errMsg = err?.response?.data?.message ? err.response.data.message.indexOf("Reason: no permissions") !== -1 ? "You don't have permissions to save" : err.response.data.message : "";
                if(errMsg){
                    notification.error({
                        message: errMsg
                    })
                }
            }) 
        } catch (error) {
            console.log(error);
        }
    }

    const focusCarousel = (elem) => {
        try{
            var x = window.scrollX, y = window.scrollY;
            elem && elem.focus();
            window.scrollTo(x, y);
        } catch(error) {
            console.log(error);
        }
    }      

    // on clicking submit after selecting area of bounding box
    const onAnnotationSubmit = (ind, url) => {
        try{
            if(url?.indexOf("no-image.png") === -1){
                let { geometry, data } = annotation;
                setAnnotation({});
                const x = geometry?.x;
                const y= geometry?.y;
                const height= geometry?.height;
                const width = geometry?.width;
                if(data===undefined){
                    data={};
                    data.text="";
                }
                if(view === 'bounding-box'){
                    const anArray = annotations.length && annotations[ind] ? [...annotations[ind]] : [];
                    const anns = anArray.concat({
                        geometry,
                        data: {
                        ...data,
                        id: Math.random()
                        }
                    });
                    const finalAnnotation = [...annotations]
                    finalAnnotation.splice(ind, 1, anns);
                    setAnnotations(finalAnnotation);
                    let boundRect = {x, y, height, width};
                    let boundArray = {text: data.text, coordinates:boundRect};
                    saveBoundingBox(ind, boundArray, finalAnnotation)
                } else {
                    setVisible(true);
                    setCoord({x,y,height, width});
                }
                focusCarousel(document.getElementsByClassName("carousel-root")[0]);
            }
        } catch(error) {
            console.log(error);
        }
    }

    const onModalCancel = () => {
        setVisible(false);
        setColorModalVisible(false);
        setGraphModalVisible(false);
        setColorBtnDisable(true);
        setOptionBtnLoading('');
        setButtonClicked(false);
    }
    
    const renderEditor=({ onChange, annotation: anno, onSubmit }) => {
        try{
            const { geometry } = anno
            if (!geometry) return null
            const imageRect = document.querySelectorAll('[id^="imageDiv"]')[0].children[0].getBoundingClientRect();
            const parentWidth = imageRect.width ?? 0 ;
            let coordPix = parentWidth * (geometry.x / 100);
            let xAxis = geometry.x;
            if(coordPix + 200 > window.innerWidth)
                xAxis = 90;
        
            return (
            <div className='editor-container'
                style={{
                left: `${xAxis}%`,
                top: `${geometry.y + geometry.height}%`,
                }}
                key={`editor_${geometry.height}${geometry.x}`}
            >
                <input className='editor-input'
                key={`editorInput_${geometry.height}${geometry.x}`}
                onChange={e => onChange({
                    ...anno,
                    data: {
                    ...anno.data,
                    text: e.target.value
                    }
                })}
                />
                <div className='editor-btn-wrapper' key={`button_${geometry.height}${geometry.x}`}>
                <button onClick={onSubmit} className='editor-btn'>Submit</button>
                </div>
            </div>
            )
        } catch(error) {
            console.log(error);
        }
    }

    const renderHighlight = ({ annotation: anno }) => {
        try{
            const { geometry } = anno;
            if (!geometry) return null
            return (
            <Box
                key={`box_${geometry.height}${geometry.x}${Math.random().toString()}`}
                geometry={geometry}
                style={{
                border: 'solid 1px red',
                }}
            >
            </Box>
            )
        } catch(error) {
            console.log(error);
        }
    }

    const [textData, setTextData] = useState([]);
    const [dominantColors, setDominantColors] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [optionBtnLoading, setOptionBtnLoading] = useState('');
    const [colorBtnDisable, setColorBtnDisable] = useState(true);
    const [colorRange, setColorRange] = useState([]);

    const filteredImages = (fn, imageIndex) => {
        try {
            const bbBoxOfCurrent = carouselData[currentSlot][imgIndex].data.bounding_box;
            const parsedBB =  (bbBoxOfCurrent.substring(1, bbBoxOfCurrent.length-1)).split(",");
            const currentImgHeight = parseInt(parsedBB[3])-parseInt(parsedBB[1]);
            const currentImgWidth = parseInt(parsedBB[2])-parseInt(parsedBB[0]);
            const imageRect = document.querySelectorAll('[id^="imageDiv"]')[0].children[0].getBoundingClientRect();
            const widthpx = coord.width/100*imageRect.width;
            const heightpx = coord.height/100*imageRect.height;
            const xpx = coord.x/100*imageRect.width;
            const ypx = coord.y/100*imageRect.height;
        
            const widthScale = (widthpx/imageRect.width)*currentImgWidth;
            const heightScale = (heightpx/imageRect.height)*currentImgHeight;
            const xScale = (xpx/imageRect.width)*currentImgWidth;
            const yScale = (ypx/imageRect.height)*currentImgHeight;
            const url = `${ROOT}/api/filtered-images`;
            let imageKey = '';
            let startColor = [];
            let endColor = [];
            let areaQueryObj = "";
            if(fn === "openColorModal"){
                if(imageIndex === 0){
                    if(mappingImageKey){
                        imageKey = {
                            key: mappingImageKey,
                            value: carouselData[currentSlot][imgIndex] && carouselData[currentSlot][imgIndex].data[mappingImageKey] ? carouselData[currentSlot][imgIndex].data[mappingImageKey] : '',
                            operator: "=",
                            operand: 'AND'
                        }
                    }
                    setOptionBtnLoading('color');
                } else {
                    setOptionBtnLoading('allColor');
                }
            }
            if(fn === "submitAreaQuery"){
                startColor = colorRange.map(val=>{
                    return val-10;
                });
                
                endColor = colorRange.map(val=>{
                    return val+10;
                });

                areaQueryObj = {
                    area_coordinates:  [[parseInt(xScale),parseInt(yScale)],[parseInt(xScale+widthScale), parseInt(yScale+heightScale)]],
                    startRange: startColor,
                    endRange: endColor
                };
            }
            const payLoad = {
                groups : filters,
                date: observedAtDetail && observedAtDetail.dateRange ? observedAtDetail.dateRange : '',
                index: indexValue,
                alias: aliasFlag ? indexAlias : '',
                area_coordinates: [[parseInt(xScale), parseInt(yScale)], [parseInt(xScale+widthScale), parseInt(yScale+heightScale)]],
                startRange: startColor?.length ? startColor : [0,0,0],
                endRange: endColor?.length ? endColor :[255,255,255],
                mappingKey: mappingImageKey,
                imageKey: imageKey ? imageKey : '' 

            };
            axiosConfig.post(url, payLoad).then(resp => {
                if(resp?.data?.data){
                    const data = resp.data.data;
                    if(fn === "openGraphModal"){
                        setVisible(false);
                        if(data.length>0){
                            let counts = {};
                            const dataArr = [];
                            data.forEach((el) => {
                                if(el?.data?.coordinateData.length){
                                    el.data.coordinateData.forEach((co) => {
                                        if(co.description !== ""){
                                            counts[co.description] = counts[co.description] ? counts[co.description] + 1 : 1;
                                        }
                                    })
                                }
                            });
                            Object.keys(counts).map((key) => dataArr.push([key, counts[key]]));
                            setTextData(dataArr);
                            setGraphModalVisible(true);
                            setButtonClicked(false);
                            setOptionBtnLoading('');
                        } else {
                            setButtonClicked(false);
                            setOptionBtnLoading('');
                            notification.error({
                                message: "No text found within selected area"
                            });
                            return;
                        }
                    }
                    if(fn === "openColorModal"){
                        setVisible(false);
                        if(data.length > 0){
                            let counts = {};
                            let colorArr = [];
                            data.forEach((el)=>{
                                el.data.coordinateData.forEach((co)=>{
                                    counts[co.bgColor] = counts[co.bgColor] ? counts[co.bgColor] + 1 : 1;
                                });         
                            });
                            colorArr = Object.keys(counts).map((color)=>{
                                return "rgb("+ color + ")";
                            });
                            setDominantColors(colorArr);
                            setColorModalVisible(true);
                            setButtonClicked(false);
                            setOptionBtnLoading('');
                        } else {
                            setIsLoading(false);
                            setButtonClicked(false);
                            setOptionBtnLoading('');
                            notification.error({
                                message: "No text found within selected area"
                            });
                            return;
                        }
                    }
                    if(fn === "submitAreaQuery") {
                        const annotArr = [];
                        const eventDataArr = [];
                        const dataIds = [];
                        if(data.length > 0){
                            setColorModalVisible(false);
                            data.forEach((el) => {
                                if(el && el.eventData && el.eventData._source){
                                    eventDataArr.push({...el.data, ...el.eventData._source});
                                    dataIds.push(el.eventData._id);
                                }
                                const coorArr = [];
                                el.data.coordinateData.forEach((co) => {
                                    const obj={};
                                    obj['geometry']={};
                                    const scalex = imageRect.width/el.data.width*co.vertices.coordinates[0][0][0];
                                    const scaley = imageRect.height/el.data.height*co.vertices.coordinates[0][0][1];
                                    const scalewidth = imageRect.width/el.data.width*co.width;
                                    const scaleheight = imageRect.height/el.data.height*co.relativeHeight;
                                    obj['geometry'].x=scalex/imageRect.width*100;
                                    obj['geometry'].y=scaley/imageRect.height*100;
                                    obj['geometry'].width=scalewidth/imageRect.width*100;
                                    obj['geometry'].height=scaleheight/imageRect.height*100;
                                    obj['data']= {};
                                    obj['data'].text = co.description;
                                    obj['data'].key = Math.random();
                                    obj['geometry'].type="RECTANGLE";
                                    coorArr.push(obj);
                                });         
                                annotArr.push(coorArr);
                            });
                            dispatch(saveImageKeys([]));
                            setcurrentSlot(0);
                            setImgIndex(0);
                            setToggleCarousel(!toggleCarousel);
                            setCarouselData([]);
                            setQueryData(eventDataArr);
                            dispatch(saveSearchData(eventDataArr));
                            dispatch(saveAllDataIds(dataIds));
                            dispatch(saveAreaQuery(areaQueryObj));
                            setAnnotations(annotArr);
                            setColorBtnDisable(true);
                            setOptionBtnLoading('');
                            setButtonClicked(false);
                        } else {
                            setIsLoading(false);
                            setColorBtnDisable(true);
                            setOptionBtnLoading('');
                            setButtonClicked(false);
                            notification.error({
                                message: "No text found within selected area"
                            });
                            return;
                        }
                    }
                }
                if(resp?.data?.dateHistogramData?.aggregations?.images_count?.buckets && fn === "submitAreaQuery"){
                    if (resp.data.dateHistogramData.aggregations.images_count.buckets.length > 0) {
                        const graphArray = [];
                        resp.data.dateHistogramData.aggregations.images_count.buckets.forEach(obj => {
                            const graphObj = [];
                            graphObj.push(obj.key, obj.doc_count);
                            graphArray.push(graphObj);
                        });
                        setGraphdata(graphArray);
                    } else {
                        setGraphdata([[]])
                    }
                }
            });
        } catch(error) {
            console.log(error);
        }
    }

    //shwoing graph for area query
    const openGraphModal = () => {
        try {
            if(carouselData[currentSlot][imgIndex] && carouselData[currentSlot][imgIndex].data.bounding_box){
                setButtonClicked(true);
                setOptionBtnLoading('graph');
                filteredImages("openGraphModal", null);
            } 
        } catch(error) {
            console.log(error);
        }
    }

    // for opening pop up on clicking choose color option
    const openColorModal = (i)=>{
        try {
            if(carouselData[currentSlot][imgIndex] && carouselData[currentSlot][imgIndex].data.bounding_box){
                setButtonClicked(true);
                filteredImages("openColorModal", i);
            } 
        } catch(error) {
            console.log(error);
        }
    }
    
    // showing images on selecting colors for area selected
    const submitAreaQuery = ()=>{
        try {
            if(carouselData[currentSlot][imgIndex] && carouselData[currentSlot][imgIndex].data.bounding_box){
                setinitialCall(true);
                setIsLoading(true);
                setColorModalVisible(false);
                filteredImages("submitAreaQuery", null);
            } 
        } catch(error) {
            console.log(error);
        }
    }
    
    // on selecting color from dominant colors for that area
    const onSelectColor = (e, x)=>{
        try{
            let colorArr= [];
            const rgb = e.rgb;
            colorArr = Object.keys(rgb).map((color)=>{
                return rgb[color];
            });
            colorArr.splice(-1,1);
            setColorRange(colorArr);
            setColorBtnDisable(false);
        } catch(error) {
            console.log(error);
        }
    }


    // move images in carousel by keyboard keys
    const clickHandler = e => {
        try{
            if(e?.target?.nodeName === "DIV" || e?.target?.nodeName === "BUTTON"){
                if (e.ctrlKey) {
                    if(e.keyCode === 38){
                        e.preventDefault();
                        return document.getElementsByClassName('control-dots')[0]?.firstElementChild?.click();
                    }
                } else {
                    if(e.keyCode === 38){
                        e.preventDefault();
                        return document.getElementsByClassName('control-dots')[0]?.firstElementChild?.click();
                    }
                    if(e.keyCode === 40){
                        e.preventDefault();
                        return document.getElementsByClassName('control-dots')[0]?.lastElementChild?.click();
                    }
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

    const fetchAnnoations = (index) =>{
        try{
            if(carouselData.length > 0 && view === 'bounding-box'){
                annotations.length = lastIndex;
                const imageName = carouselData[currentSlot][index] && carouselData[currentSlot][index].data[mappingImageKey] ? carouselData[currentSlot][index].data[mappingImageKey] : '';
                if(imageName){
                    if (typeof cancelToken != typeof undefined) {
                        cancelToken.cancel("Operation canceled due to new request.")
                      }

                    cancelToken = axios.CancelToken.source()
                    const url = `${ROOT}/api/get/boundingbox/${imageName}`;
                    axiosConfig.get(url, { cancelToken: cancelToken.token }).then(response=>{
                        if(response && response.data){
                            const data = response.data;
                            if(data && data[mappingImageKey] === imageName){
                                let bb_det = [];
                                if(Array.isArray(data.boundingbox_details)){
                                    bb_det = data.boundingbox_details.map((bb)=>{
                                        const obj={};
                                        obj['geometry']= bb.coordinates ? bb.coordinates : {};
                                        obj['data']= {};
                                        obj['data'].text = bb?.text;
                                        obj['data'].id = Math.random();
                                        obj['geometry'].type="RECTANGLE";
                                        return obj;
                                        });
                                } else {
                                    const obj={};
                                    obj['geometry']=  data?.boundingbox_details?.coordinates ? data.boundingbox_details.coordinates: {};
                                    obj['data']= {};
                                    obj['data'].text = data?.boundingbox_details?.text;
                                    obj['data'].id = Math.random();
                                    obj['geometry'].type="RECTANGLE";
                                    bb_det[0] = obj;
                                }
                                const finalAnnotation= [...annotations]
                                finalAnnotation.splice(index, 1, bb_det);
                                setAnnotations(finalAnnotation);
                            } else {
                                const finalAnnotation= [...annotations]
                                finalAnnotation.splice(index, 1, []);
                                setAnnotations(finalAnnotation);
                            }
                        }
                    })
                    .catch((thrown) => {
                        if (axios.isCancel(thrown)) {
                          console.log('Request canceled', thrown.message);
                        } 
                    });
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

    const formatStatus = (currentItem, total) =>{
        return <><strong>Slot {currentSlot + 1}:</strong> &nbsp;{currentItem} of {total}</>;
    }
    
    const imageDiv = useRef(null);
    return (
        <>
            {carouselData.length > 0 && (
                <CarouselSlider
                    className={`carousel ${isConfigureFieldEnable ? '' : 'noFieldSelected'}`}
                    onChange={event=>handleChange(event)}
                    showThumbs={false}
                    key={toggleCarousel}
                    autoFocus={true}
                    selectedItem={selectedSlide}
                    useKeyboardArrows={true}
                    statusFormatter={formatStatus}
                    infiniteLoop={false}
                    renderArrowNext={( onClickHandler, hasNext, labelNext ) =>
                        (
                            <button onClick={onClickHandler} className="right-arrow-button">
                                <RightOutlined className="right-outlined" />
                            </button>
                        )
                    }
                    renderArrowPrev={( onClickHandler, hasNext, labelNext ) =>
                        (
                            <button onClick={onClickHandler} className="left-arrow-button">
                                <LeftOutlined className="left-outlined"/>
                            </button>
                        )
                    }

                    >
                    {carouselData[currentSlot] && carouselData[currentSlot].map((res, ind) => {
                        return (
                            <div className={`img-wrapper img-wrapper_${ind}`} key={`img-wrapper_${ind}`}>
                                {isConfigureFieldEnable && (
                                    <div className={`img-data img-data_${ind}`} key={`img-data_${ind}`}>
                                        {res.data &&
                                            (
                                                Object.keys(res.data).map((elm, index)=>{
                                                    return selectedOption.map((opt)=>{
                                                        if(elm === opt.value && opt.isChecked === true && res.data[elm]){
                                                            return <div style={{wordBreak: `${elm === 'url' ? 'break-all' : 'unset'}`}} className="fieldInfo" key={`dataOptVal_${index}`}><div className="fieldValue"><strong>{elm}:</strong> {res.data[elm] === true ? "Yes" : res.data[elm] === false ? "No" : typeof res.data[elm] === "string" || typeof res.data[elm] === "number" ? res.data[elm] : ""}</div></div>
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                })
                                            )
                                        }
                                    </div>
                                )}
                                <div ref={imageDiv} id={"imageDiv"+ind} key={"imageDiv"+ind}>
                                    <RenderImage 
                                        ind={ind}
                                        imageData={res}
                                        annotations={annotations}
                                        annotation={annotation}
                                        renderEditor={renderEditor}
                                        renderHighlight={renderHighlight}
                                        onAnnotationChange={setAnnotation}
                                        onAnnotationSubmit={onAnnotationSubmit}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </CarouselSlider>
            )}
            
            <Modal
                visible={visible}
                className="optionsModal"
                title="Please Select The Options Below"
                onCancel={onModalCancel}
                maskClosable={false}
                width={350}
                footer={[
                    <Button loading={optionBtnLoading === 'graph' ? true : false} disabled={optionBtnLoading === 'graph' ? false : buttonClicked} onClick={openGraphModal} type="primary" key="Show graph" id="openGraph">Show Graph</Button>,
                    <Button loading={optionBtnLoading === 'color' ? true : false} disabled={optionBtnLoading === 'color' ? false : buttonClicked} onClick={()=>openColorModal(0)} type="primary" key="Choose color" id="colorImage">Choose Color From Images</Button>,
                    <Button loading={optionBtnLoading === 'allColor' ? true : false} disabled={optionBtnLoading === 'allColor' ? false : buttonClicked} onClick={()=>openColorModal(1)} type="primary" key="Choose all colors" id="colorAll">Choose All Colors</Button>
                ]}
            />
            <Modal
                visible={colorModalVisible}
                className="colorModal"
                title="Please select color of screen signature "
                onCancel={onModalCancel}
                maskClosable={false}
                footer={[
                    <Button className="btnSubmitColor" disabled={colorBtnDisable} onClick={submitAreaQuery} type="primary" key="Submit color">Submit</Button>
                ]}
            >
                <CirclePicker className="circlePicker" width={'100%'} colors={dominantColors} onChange={onSelectColor}/>
            </Modal>
            <Modal
                visible={graphModalVisible}
                className="graphModal"
                title="Word Occurrence Graph"
                onCancel={onModalCancel}
                maskClosable={false}
                footer={[]}
            >
                {textData.length > 0 ? (
                    <Chart size={{height: 300}}>
                        <Settings
                            showLegend={false} rotation={90}
                        />
                        <BarSeries
                            id="text_count"
                            name="text_count"
                            data={textData}
                            xAccessor={0}
                            yAccessors={[1]}
                        />  
                        <Axis   
                            id="bottom-axis"
                            position="bottom"
                        />
                        <Axis
                            id="left-axis"
                            position="left"
                        />
                    </Chart>)
                        : null
                }
            </Modal>
        </>
    )
}

export default Carousel;
