import React, { useState, useRef, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import './Dashboard.scss';
import { ToastContainer } from "react-toastr";
import '../../toastr.min.css';
import "animate.css/animate.css";
import ToastMessagejQuery from "react-toastr/lib/components/ToastMessage/ToastMessagejQuery";
import Graph from "../Graph";
import Search from '../Search';
import Carousel from "../Carousel";
import { Layout, Card } from 'antd';
import Loader from '../Loader';
import { useDispatch, useSelector } from 'react-redux';
import TopHeader from '../TopHeader';
import { saveRouteLocation } from '../../store/actions';
const { Content } = Layout;

const Dashboard = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const pathName = location?.pathname ? location?.pathname : "";
  const bodyEl = document.getElementById('bodyEl');
  if(bodyEl){
    bodyEl.classList.add('dashboardBg');
    bodyEl.classList.remove('loginBg');
  }
  const {selectedBucket, graphVisibility, indexValue, uuids} = useSelector(store => store.storeProps);
  const container = useRef(null);
  const [queryData, setQueryData] = useState([]);
  const [enableExport, setEnableExport] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [areaQuery, setAreaQuery] = useState('');
  const [queryDate, setQueryDate] = useState('');
  const [annotations, setAnnotations] = useState([]);
  const [graphdata, setGraphdata] = useState([]);
  const [graphEvent, setGraphEvent] = useState('');
  const [norecords, setNoRecord]= useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [graphVisible, setGraphVisible] = useState(graphVisibility ? graphVisibility : false);
  const [finalQuery, setFinalQuery] = useState('');
  const token = uuids?.uuid1;
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    dispatch(saveRouteLocation(pathName));
    // eslint-disable-next-line
  }, [])

  const rendertitleContents = () => {
    return ( 
        <Search
          setQueryData={setQueryData}
          queryData={queryData}
          setGraphVisible={setGraphVisible}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setEnableExport={setEnableExport}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setAreaQuery={setAreaQuery}
          setGraphdata={setGraphdata}
          setQueryDate={setQueryDate}
          setAnnotations={setAnnotations}
          graphdata={graphdata}
          enableExport={enableExport}
          setGraphEvent={setGraphEvent}
          graphEvent={graphEvent}
          setNoRecord={setNoRecord}
          norecords={norecords}
          isDataLoading={isDataLoading}
          initialQueryStack={[]}
          setFinalQuery={setFinalQuery}
          finalQuery={finalQuery}
        />)
  }
  
  return (
    <>
      {!token ?
        (<Redirect to="/login" />) : (
          <>
            {!indexValue ? (
              <Redirect to="/indices" />
            ) : (
              <>
                {isLoading && (
                  <Loader />
                )}
                <TopHeader bucket={true} setIsLoading={setIsLoading} />  
                <ToastContainer ref={container} className="toast-top-right" toastMessageFactory={React.createFactory(ToastMessagejQuery)} />
                <div className="savedQueriesInput">
                    <Layout className={`${(queryData.length === 0  || norecords) ? 'heightVh' :''}`}>
                      <Content>
                        <Card title={rendertitleContents()} className={`${queryData.length === 0 || norecords ? 'no-records': ''}`}>
                            {queryData.length === 0 || norecords ? (
                              isLoading ? (
                                <div></div>
                              ):
                              <div className="no-records-wrapper">
                                <img src="./empty-space.png" alt="FIQ" />
                                {selectedBucket?.bucketName ? (
                                  <>
                                    <p className="no-records-head">No Results Found</p>
                                    <p className="no-records-subhead">Please change the <strong>date</strong> to continue</p>
                                  </>
                                ) : (
                                  <p className="no-records-subhead">Please select the <strong>bucket</strong> to continue</p>
                                )}
                              </div>
                            ):(
                              <>
                              {
                                graphdata && graphVisible &&
                                <Card.Grid hoverable={false}  className="gridContent"> 
                                  <Graph graphVisible={graphVisible} graphdata={graphdata} setGraphEvent={setGraphEvent}  />
                                </Card.Grid>
                              }
                              <Card.Grid hoverable={false} className="gridContent carouselContent">
                                <Carousel 
                                      queryData={queryData} 
                                      setQueryData={setQueryData}
                                      selectedOption={selectedOption} 
                                      areaQuery={areaQuery}
                                      queryDate={queryDate}
                                      setAnnotations={setAnnotations}
                                      annotations={annotations}
                                      setIsLoading={setIsLoading}
                                      setGraphdata={setGraphdata}
                                      setIsDataLoading={setIsDataLoading}
                                />
                              </Card.Grid>
                            </>
                            )}
                          </Card>
                      </Content>
                    </Layout>
                </div>
              </>
            )}
          </>
        )
      }
    </>
  );
};

export default Dashboard;
