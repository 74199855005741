import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Form, Input, notification, Switch, Select, Checkbox } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateEnvarsStatus, saveIndexValue, saveMappingKey, saveGcpFileName, saveEnvars, saveFileConfigurations, saveUuids, saveRouteLocation, saveSessionFlag } from "../../store/actions";
import ROOT from '../../client';
import "./envarStyle.scss";
import Footer from '../Footer';
import { Redirect, useLocation } from 'react-router-dom';
import * as helper from "../helper";
import axiosConfig from '../../axiosConfig';

const {Option} = Select;
const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
};
const tailLayout = {
    wrapperCol: { offset: 12, span: 12 },
};

const EnvarsConfig = () => {
    const location = useLocation();
    const pathName = location?.pathname ? location?.pathname : "";
    const bodyEl = document.getElementById('bodyEl');
    if(bodyEl){
        bodyEl.classList.add('dashboardBg');
        bodyEl.classList.remove('loginBg');
    }
    const { updateEnvars, envars } = useSelector(store => store.storeProps);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [disableField, setDisableField] = useState(true);
    const [storage, setStorage] = useState('');
    const [isfileUploaded, setIsfileUploaded] = useState(true);
    const [fileError, setFileError] = useState({
        status: false,
        message: '',
        className: "errorMessage"
    });
    const [elasticChecked, setElasticChecked] = useState(false);
    const [formValsChanged, setFormValsChanged] = useState(false);

    useEffect(() => {
        try{
            if(updateEnvars){
                setDefaultChecked(false);
                setDisableField(false);
            }
            dispatch(saveRouteLocation(pathName))
            dispatch(saveEnvars(''));
            dispatch(saveFileConfigurations(''));
            dispatch(saveUuids({}))
            defaultEnvars();
            dispatch(saveSessionFlag(false));
        } catch(error) {
            console.log("useEffect error: ", error);
        }
        // eslint-disable-next-line
    }, [])

    const [defaultData, setDefaultData] = useState('');
    const defaultEnvars = () => {
        try{
            axiosConfig.get(`${ROOT}/api/get-env`)
            .then(resp => {
                if(resp && resp.data && resp.data.statusCode === 200 && resp.data.envData){
                    let envarData = resp.data.envData;
                    const essl = envarData.elasticSsl && envarData.elasticSsl === 'true' ? true : false;
                    const ussl = envarData.useSsl ? envarData.useSsl === 'true' ? true : false : true;
                    const mPort = envarData.minioPort ? JSON.stringify(envarData.minioPort) : '';
                    envarData = {...envarData, elasticSsl: essl, useSsl: ussl, minioPort: mPort, elasticType: envarData.elasticType && envarData.elasticType !== 'OPENDISTRO' ? envarData.elasticType.toLowerCase() : envarData.elasticType};
                    setElasticChecked(essl);
                    setDefaultData(envarData);
                    form.setFieldsValue(envarData);
                    setStorage(envarData.storageMechanism ? envarData.storageMechanism : '');
                    setIsfileUploaded(true);
                    dispatch(saveEnvars(envarData));
                    dispatch(saveGcpFileName(envarData.fileName ? envarData.fileName : 'Required file already uploaded.'));
                } else {
                    setDefaultChecked(false);
                    setDisableField(false);
                }
            })
            .catch(error => {
                console.log(error);
                if(error?.response?.data?.message){
                    notification.error({
                        message: error.response.data.message,
                        duration: 5
                    }) 
                }
            })
        } catch(error) {
            console.log("defaultEnvarsData error: ", error);
        }
    }
    const [isValid, setIsValid] = useState(
        {
            status: false,
            message: "",
            className: "errorMessage"
        }
    )
    const onFileUpload = (e) => {
       try{
          setIsValid({
            status: true,
            message: "",
            className: "errorMessage"
          })
          setFormValsChanged(false);
          let file = e.target.files[0];
          if(file && file.type === "text/plain"){
            let reader = new FileReader();
            reader.onload = function(event) {
              if(event.target.result){
                const fileData = helper.isValidJson(event.target.result);
                if(fileData && fileData.elasticSsl && fileData.elasticType && fileData.elasticUrl && fileData.storageMechanism && fileData.indices){
                    setFormValsChanged(true);
                    const elasticSslVal = fileData.elasticSsl && fileData.elasticSsl === "true" ? true : false;
                    if(fileData.useSsl){
                        const useSslVal = fileData.useSsl === "true" ? true : false;
                        fileData.useSsl = useSslVal;
                    }
                    setElasticChecked(elasticSslVal);
                    fileData.imageExtension = fileData.imageExtension && fileData.imageExtension === "NA" ? "" : fileData.imageExtension;
                    form.setFieldsValue(fileData);
                    if(fileData.storageMechanism !== "gcp"){
                        dispatch(saveFileConfigurations(fileData));
                    }
                    setIsValid({
                        status: false,
                        message: "File is uploaded successfully.",
                        className: "successMessage"
                    })
                } else {
                  setIsValid({
                    status: false,
                    message: "File seems to be not correct.",
                    className: "errorMessage"
                  })
                }
              }
            };
            reader.readAsText(file);
          } else {
            setIsValid({
              status: false,
              message: "Please choose text file only.",
              className: "errorMessage"
            })
          }
        } catch(error) {
          console.log("File upload error: ", error);
        }
    }

    const fileValidationMsg = (status, message, className) => {
        try{
            setFileError({
                status: status,
                message: message,
                className: className
            });
        } catch(error) {
            console.log(error);
        }
    }
    const [defaultChecked, setDefaultChecked] = useState(true);
    const defaultValHandler = (e) => {
        try{
            const value = e?.target?.checked ? true : false;
            if(value){
                form.setFieldsValue(defaultData);
                setElasticChecked(defaultData.elasticSsl);
                // setUseChecked(defaultData.useSsl);
                // fileInfo(gcpFileName);
                dispatch(saveFileConfigurations(''));
            }
            setDefaultChecked(value);
            dispatch(updateEnvarsStatus(false));
            setDisableField(value);
            setIsfileUploaded(true);
            setStorage(defaultData?.storageMechanism ? defaultData.storageMechanism : '');
            // setFileRequired(false);
            fileValidationMsg(false, '', 'errorMessage');
            setFormValsChanged(false);
            // setRemoveFile(value ? false : true);
        } catch(error) {
            console.log("defaultValHandler error: ", error);
        }
    }

    const storageHandler = (name) => {
        try{
            if(name){
                setStorage(name);
                fileValidationMsg(false, '', 'errorMessage');
            }
        } catch(error) {
            console.log("storageHandler error: ", error);
        }
    }

    const [isConfigure, setIsConfigure] = useState(false);
    const onFinish = (formValues) => {
        try{
            if(formValsChanged){
                if(formValues && isfileUploaded){
                    setIsLoading(true);
                    const fileName = formValues.gcpGoogleAppCredentials?.file?.name ? formValues.gcpGoogleAppCredentials.file.name : '';
                    const url = `${ROOT}/api/configure-env`;
                    const lofValue = formValues?.likeOpFields ? formValues.likeOpFields : '';
                    const lofArr = lofValue ? lofValue.indexOf(",") !== -1 ? lofValue.split(",") : [lofValue] : '';
                    const payload = {...formValues, likeOpFields: lofArr, gcpGoogleAppCredentials: fileName ? true : false, fileName: fileName ? fileName : ''};
                    axiosConfig.post(url, payload)
                    .then(resp => {
                        if(resp && resp.data && resp.data.statusCode === 200){
                            setIsLoading(false);
                            setIsConfigure(true);
                            dispatch(saveEnvars({
                                ...envars,
                                storageMechanism: payload.storageMechanism
                            }));
                            dispatch(updateEnvarsStatus(false));
                            localStorage.removeItem("indexVal");
                            localStorage.removeItem("defaultImageKey");
                            localStorage.removeItem("selectedIndexPattern");
                            dispatch(saveMappingKey(''));
                            dispatch(saveIndexValue({
                                index: '',
                                alias: '',
                                aliasFlag: false,
                                allIndexes: '',
                                aliasIndexesArr: []
                            }));
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        if(error?.response?.data?.message){
                            notification.error({
                                message: error.response.data.message,
                                duration: 5
                            }) 
                        }
                        setIsLoading(false);
                    })
                } else {
                    fileValidationMsg(true, 'Please upload gcp key file.', 'errorMessage');
                }
            } else {
                setIsConfigure(true);
                dispatch(saveEnvars({
                    ...envars,
                    storageMechanism: storage ? storage : ''
                }));
            }
        } catch(error) {
            console.log("function onFinish error: ", error);
        }
    }

    const removeSpace = (e) => {
        try{
            if(e?.target?.value){
                let fieldVal = e.target.value;
                const fieldId = e.target.id;
                fieldVal = fieldVal.replace(/\s/g,'');
                form.setFieldsValue({
                    [fieldId]: fieldVal
                })
            }
        } catch(error) {
            console.log("removeSpace error: ", error);
        }
    }

    const onValuesChange = () => {
        setFormValsChanged(true);
    }

    const validatorRule = [{
        validator(_, value) {
            if(value){
                value = value.trim();
                if(value){
                    return Promise.resolve()
                } else {
                    return Promise.reject(new Error(`Required field.`))
                }
            } else {
                return Promise.reject(new Error(`Required field.`))
            }
        }
    }];
    
    return (
        <>
            {isConfigure && (
                <Redirect to="/login" />
            )}
            <div className="flex-container-column">
                <div className="logo-login">
                    <img src="./fortress-logo-login.png" alt="FIQ" />
                </div>
                <div className="flex-container-row envarFlexRow">
                    <div className="envarWrapper">
                        <div className="loginHead">
                            <h1>Configuration Settings</h1>
                            {disableField ? (
                                <p>Uncheck to change the configuration</p>
                            ) : (
                                <p>Check to default reset configuration settings</p>  
                            )}
                            <Row>
                                <Col span={24} className="defaultCheckbox">
                                    <label>Default Configuration <Checkbox id="defaultCheckbox" checked={defaultChecked} onChange={defaultValHandler} /></label>
                                </Col>
                            </Row>
                        </div>
                        {!disableField && ( 
                            <>
                                <div className="uploadConfigWrapper">
                                    <Row>
                                        <Col span={12}>
                                            <label>Upload Configuration File:</label>
                                        </Col>
                                        <Col span={12}>
                                            <input id="fileInput" type="file" onChange={onFileUpload}/>
                                            {!isValid.status && (
                                                <div id="infoDiv" className={isValid.className}>{isValid.message}</div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                                <h3 className="orHeading">OR<br></br><span>Set your own configurations</span></h3>
                            </>
                        )}
                        <Row className="envarsRow">
                            <Col span={24} className="envarsCol">
                                <Form
                                    form={form}
                                    name="envarsConfigform"
                                    onFinish={onFinish}
                                    onValuesChange={onValuesChange}
                                    className="formEnvars"
                                    {...layout}
                                    initialValues={{
                                        elasticType: 'OPENDISTRO',
                                        elasticSsl: false,
                                        imageExtension: '',
                                        likeOpFields: 'ocr_text',
                                        useSsl: true
                                    }}
                                    >
                                    <Form.Item
                                        label={<>Elastic Search SSL</>}
                                        name="elasticSsl"
                                        tooltip={{ title: 'SSL status of elastic. Keep it false for unauthorized elastic instance.', icon: <QuestionCircleFilled /> }}
                                    >
                                        <Switch disabled={disableField} checkedChildren="True" unCheckedChildren="False" checked={elasticChecked} onChange={() => setElasticChecked(!elasticChecked)}/>
                                    </Form.Item>
                                    <Form.Item
                                        label={<>Elastic Type</>}
                                        name="elasticType"
                                        tooltip={{ title: 'Type of elastic instance', icon: <QuestionCircleFilled /> }}
                                    >
                                        <Select disabled={disableField}>
                                            <Option value="normal">Normal</Option>
                                            <Option value="OPENDISTRO">OPENDISTRO</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label={<>Elastic Search Host URL</>}
                                        name="elasticUrl"
                                        tooltip={{ title: 'Elastic end point e.g https://host:port', icon: <QuestionCircleFilled /> }}
                                        rules={validatorRule}
                                    >
                                        <Input id="elasticUrl" onChange={removeSpace} disabled={disableField} placeholder="https://host:port" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<>Indices</>}
                                        name="indices"
                                        tooltip={{ title: 'Please enter required elastic indices that a user should have access.', icon: <QuestionCircleFilled /> }}
                                        extra={disableField ? "" : "Please add comma separated for multiple values. For example event_logs,userdata"}
                                        rules={validatorRule}
                                    >
                                        <Input id="indices" onChange={removeSpace} disabled={disableField} placeholder="event_logs,userdata" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<>LIKE Operator Fields</>}
                                        name="likeOpFields"
                                        tooltip={{ title: 'Please enter that field names where you want LIKE operator while adding the filters.', icon: <QuestionCircleFilled /> }}
                                        extra={disableField ? "" : "Please add comma separated for multiple values. For example ocr_text,title"}
                                        rules={[{
                                            validator(_, value) {
                                                if(value){
                                                    value = value.trim();
                                                    if(value){
                                                        return Promise.resolve()
                                                    } else {
                                                        return Promise.reject(new Error(`Required field.`))
                                                    }
                                                } else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        }]}
                                    >
                                        <Input id="likeOpFields" onChange={removeSpace} disabled={disableField} placeholder="ocr_text,title" />
                                    </Form.Item>
                                    <Form.Item
                                        label={<>Image Extension</>}
                                        name="imageExtension"
                                        tooltip={{ title: 'Please enter file extension for image file (if not included in data).', icon: <QuestionCircleFilled /> }}
                                        extra={disableField ? "" : "Please add image format like this .jpeg"}
                                        rules={[{
                                            validator(_, value) {
                                                if(value){
                                                    value = value.trim();
                                                    if(value){
                                                        if(value === ".jpeg" || value === ".jpg" || value === ".png" || value === ".svg" || value === ".gif") {
                                                            return Promise.resolve()
                                                        } else {
                                                            return Promise.reject(new Error(`Invalid format`))
                                                        }
                                                    } else {
                                                        return Promise.reject(new Error(`Invalid format.`))
                                                    }
                                                } else {
                                                    return Promise.resolve()
                                                }
                                            }
                                        }]}
                                    >
                                        <Input id="imageExtension" onChange={removeSpace} disabled={disableField} placeholder={disableField ? "" : ".jpeg"} />
                                    </Form.Item>
                                    <Form.Item
                                        label={<>Storage Mechanism</>}
                                        name="storageMechanism"
                                        tooltip={{ title: 'Please select cloud storage', icon: <QuestionCircleFilled /> }}
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Required field.',
                                            }
                                        ]}
                                    >
                                        <Select id="stm" disabled={disableField} onChange={storageHandler} placeholder="Select cloud storage">
                                            <Option value="aws">AWS</Option>
                                            <Option value="azure">Azure</Option>
                                            <Option value="gcp">GCP</Option>
                                            <Option value="minio">Minio</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item {...tailLayout}>
                                        {fileError && (
                                            <div id="errorDiv" className={fileError.className}>{fileError.message}</div>
                                        )}
                                        <Button id="SubmitBtn" block loading={isLoading} className="envarButton" type="primary" htmlType="submit">Get Started</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default EnvarsConfig;