import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
const { Option } = Select;

const ImageKeys = (props)=>{
    const {imageDetail} = useSelector(store => store.storeProps);
    const { onSelectImageKey, imageKey } = props;

    return (
        <Select
            style={{ width: '100%'}}
            placeholder="Select a value"
            onChange={onSelectImageKey}
            defaultValue={imageKey}
            showSearch
            className="index-select"
        >
            {imageDetail && imageDetail.length > 0 && imageDetail.map((element, index) => {
                return <Option key={index} value={element.label}>{element.label}</Option>
            })}
        </Select> 
    )
}

export default ImageKeys;