import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { Modal } from 'antd';
import ROOT from '../src/client';
import { store, toObservable } from './store/store';
import axiosConfig from './axiosConfig';
import { saveSessionFlag, saveUuids } from './store/actions';

let refreshToken = null;
try {
  const state = toObservable(store);
  state.subscribe({ onNext: (state) => {
    refreshToken = state?.storeProps?.uuids?.uuid2
  }
  })
} catch(err) {}


axiosConfig.interceptors.response.use(undefined, (error) => {
  const originalRequest = error.config;
  if (error?.response?.status === 401 && error?.response?.config?.url === `${ROOT}/api/access-token`) {
    Modal.confirm({
      title: 'Session Expired',
      content: (
        <strong>
          Your session has expired and requires page reload and re-login is recommended else application may get
          unresponsive.
        </strong>
      )
    }).update({
      okText: 'Reload',
      onOk: () => {
        if(store){
          store.dispatch(saveUuids({}));
          store.dispatch(saveSessionFlag(false))
        }
      },
      okButtonProps: {
        className: 'btn blue-filed ok-btn'
      },
      cancelButtonProps: {
        style: { display: 'none' }
      },
      centered: true,
      maskClosable: false
    });
  }
  else if (error?.response?.status === 401){
    return new Promise(function (resolve, reject) {
      axiosConfig.defaults.headers.common['Authorization'] = refreshToken;
      axiosConfig.get(`${ROOT}/api/access-token`)
        .then(({ data }) => {
          axiosConfig.defaults.headers.common['Authorization'] =  data.uid1;
          originalRequest.headers['Authorization'] = data.uid1;
          if(store){
            store.dispatch(saveUuids({
              uuid1: data.uid1,
              uuid2: refreshToken
            }))
          }
          return resolve(axios(originalRequest));
        })
        .catch(err => {
          console.log(err, 'err')
          reject(err);
        })
    });

  }
  return Promise.reject({ ...error, intercepted: true });
});

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
