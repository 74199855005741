export const ADD_INDEX_VALUE = 'ADD_INDEX_VALUE';
export const SAVE_SEARCH_DATA = 'SAVE_SEARCH_DATA';
export const SAVE_IMG_URL = 'SAVE_IMG_URL';
export const SAVE_FILTERS = 'SAVE_FILTERS';
export const SAVE_DATE = 'SAVE_DATE';
export const SAVE_ALL_DATA_IDS = 'SAVE_ALL_DATA_IDS';
export const SAVE_MAPPING_KEY = 'SAVE_MAPPING_KEY';
export const SAVE_SCREEN_SIGNATURE = 'SAVE_SCREEN_SIGNATURE';
export const UPDATE_CONFIGURE_FIELDS = 'UPDATE_CONFIGURE_FIELDS'
export const SAVE_IMAGE_LOAD_COUNT = 'SAVE_IMAGE_LOAD_COUNT';
export const SAVE_AREA_QUERY = 'SAVE_AREA_QUERY';
export const SAVE_CONFIGURE_FIELD_STATUS = 'SAVE_CONFIGURE_FIELD_STATUS';
export const UPDATE_ENVARS = 'UPDATE_ENVARS';
export const SAVE_GCP_FILENAME = 'SAVE_GCP_FILENAME';
export const SAVE_PDD_DETAIL = 'SAVE_PDD_DETAIL';
export const SAVE_BUCKETS = 'SAVE_BUCKETS';
export const SAVE_SELECTED_BUCKET = 'SAVE_SELECTED_BUCKET';
export const SAVE_ENVARS = 'SAVE_ENVARS';
export const SAVE_SLOTS = 'SAVE_SLOTS';
export const SAVE_IMAGE_KEYS = 'SAVE_IMAGE_KEYS';
export const SAVE_FILE_CONFIGURATIONS = 'SAVE_FILE_CONFIGURATIONS';
export const SAVE_IMAGE_DETAIL = 'SAVE_IMAGE_DETAIL';
export const SAVE_MASKED_BUCKET = 'SAVE_MASKED_BUCKET';
export const SET_VIEW = "SET_VIEW";
export const SAVE_MAPPING_PROPS = "SAVE_MAPPING_PROPS";
export const SAVE_QUERY_STACK = "SAVE_QUERY_STACK";
export const SAVE_GRAPH_VISIBILITY = "SAVE_GRAPH_VISIBILITY";
export const SAVE_MINIO_CLIENT = "SAVE_MINIO_CLIENT";
export const SET_ENABLE_SEARCH = "SET_ENABLE_SEARCH";
export const SAVE_MASKED_FLAG = "SAVE_MASKED_FLAG";
export const SAVE_UUIDS = "SAVE_UUIDS";
export const SAVE_NAME = "SAVE_NAME";
export const SAVE_ROUTE_LOCATION = "SAVE_ROUTE_LOCATION";
export const SAVE_SESSION_FLAG = "SAVE_SESSION_FLAG";
