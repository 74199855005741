import { Form, Button, Input, Upload, Switch, Select, notification } from "antd";
import { QuestionCircleFilled, UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import ROOT from '../../client';
import { useSelector } from 'react-redux';
import * as helper from "../helper";
import axiosConfig from "../../axiosConfig";

const { Option } = Select;
const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};
const tailLayout = {
    wrapperCol: { offset: 10, span: 14 },
};

const UpdateBucket = (props) => {
    const [form] = Form.useForm();
    const { envars, buckets, selectedBucket, uuids } = useSelector(store => store.storeProps);
    const { bucketsOption, setBucketModalVisible, setUpdateFlag, setConfigureBucket, initialVals } = props;
    const [loading, setLoading] = useState(false);
    const [useChecked, setUseChecked] = useState(true);
    const [filesList, setFiltsList] = useState([]);
    const [fileError, setFileError] = useState({
        status: false,
        message: '',
        className: "errorMessage"
    });
    const token = uuids?.uuid1; 
    useEffect(() => {
        if(selectedBucket?.bucketName){
            form.setFieldsValue({
                bucketName: selectedBucket?.bucketName ? selectedBucket?.bucketName : '',
                bucketFolderName: selectedBucket?.bucketFolderName ? selectedBucket?.bucketFolderName : ''
            })

        }
        // eslint-disable-next-line
    }, [selectedBucket])

    const handleCancel = () => {
        setBucketModalVisible(false);
        form.resetFields();
        setFiltsList([]);
        fileValidationMsg(false, '', 'errorMessage');
    };

    const onUpdate = (values) => {
        try{
            if(values){
                let isFileUploaded = true;
                let bucketInfo = '';
                let bname = '';
                if(values.gcpGoogleAppCredentials && filesList?.length){
                    const filestamp = values.gcpGoogleAppCredentials.file?.response?.fileName ? values.gcpGoogleAppCredentials.file.response.fileName : '';
                    values.fileName = filestamp ? filestamp : '';
                    delete values.gcpGoogleAppCredentials;
                } else if(envars?.storageMechanism === 'gcp'){
                    isFileUploaded = false;
                }
                if(values.bucketName){
                    bname = values.bucketName;
                    if(buckets?.length){
                        bucketInfo = buckets.filter(val => val.bucketName === values.bucketName);
                        if(bucketInfo?.length){
                            values.metaInfo = bucketInfo[0] && bucketInfo[0].metaInfo ? bucketInfo[0].metaInfo : '';
                        }
                    }
                }
                if(values.bucketFolderName){
                    values.bucketName = `${values.bucketName}/${values.bucketFolderName}`;
                    delete values.bucketFolderName;
                }
                if(values.metaInfo){
                    if(isFileUploaded){
                        setLoading(true);
                        axiosConfig.post(`${ROOT}/api/update-bucket-data`, values).then(res => {
                            if(res){
                                setConfigureBucket(bname);
                                setLoading(false);
                                setBucketModalVisible(false);
                                form.resetFields();
                                setFiltsList([]);
                                setUpdateFlag(true);
                                fileValidationMsg(false, '', 'errorMessage');
                                notification.success({
                                    message: res.data?.data ? res.data?.data : ''
                                })
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            if(error?.response?.data?.message){
                                fileValidationMsg(true, `${error?.response?.data?.message}`, 'errorMessage');
                            }
                        })
                    } else {
                        setLoading(false);
                        fileValidationMsg(true, `Please upload required file.`, 'errorMessage');
                    }
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

    const uploadProps = {
        name: 'file',
        action: `${ROOT}/api/upload-file`,
        headers: {
            Authorization: token,
        },
        maxCount: 1,
        beforeUpload(file) {
            try{
                if (file.type !== "application/json") {
                    uploadFileError();
                    return false;
                } else {
                    return true;
                }
            } catch(error) {
                console.log(error);
            }
        },
        onChange(info) {
            try{
                if (info.file.type !== "application/json") {
                    uploadFileError();
                } else {
                    if(info?.fileList?.length){
                        setFiltsList(info.fileList);
                    }
                    if (info.file.status === 'done') {
                        fileValidationMsg(true, `${info.file.name} file uploaded successfully.`, 'successMessage');
                    } else if (info.file.status === 'error') {
                        setFiltsList([]);
                        fileValidationMsg(true, 'Something went wrong. Please try again.', 'errorMessage');
                    }
                }
            } catch(error) {
                console.log(error);
            }
        },
        onRemove() {
            setFiltsList([]);
            fileValidationMsg(false, '', 'errorMessage');
        }
    };

    const uploadFileError = () => {
        setFiltsList([]);
        fileValidationMsg(true, 'Only JSON file format is allowed.', 'errorMessage');
    }

    const fileValidationMsg = (status, message, className) => {
        try{
            setFileError({
                status: status,
                message: message,
                className: className
            });
        } catch(error) {
            console.log(error);
        }
    }

    const removeSpace = (e) => {
        try{
            if(e?.target?.value){
                let fieldVal = e.target.value;
                const fieldId = e.target.id;
                fieldVal = fieldVal.replace(/\s/g,'');
                form.setFieldsValue({
                    [fieldId]: fieldVal
                })
            }
        } catch(error) {
            console.log("removeSpace error: ", error);
        }
    }

    const buckethandler = (value) => {
        try{
            if(value){
                if(buckets?.length){
                    const bucketInfo = buckets.filter(item => item.bucketName === value);
                    if(bucketInfo?.length){
                        form.setFieldsValue({
                            bucketFolderName: bucketInfo[0]?.bucketFolderName ? bucketInfo[0].bucketFolderName : '',
                        })
                    }
                }
            }
        } catch(error) {
            console.log(error);
        }
    }

    return (
        <Form
            form={form}
            name="updateBucketForm"
            className="bucketForm"
            onFinish={onUpdate}
            labelAlign="left"
            initialValues={{
                ...initialVals,
                bucketName: selectedBucket?.bucketName ? selectedBucket?.bucketName : '',
                bucketFolderName: selectedBucket?.bucketFolderName ? selectedBucket?.bucketFolderName : ''
            }}
            {...layout}
        >
            <Form.Item
                label={<>Bucket Name</>}
                name="bucketName"
                tooltip={{ title: 'Please select bucket name', icon: <QuestionCircleFilled /> }}
                rules={[
                    {
                        required: true,
                        message: 'Required field.',
                    }
                ]}
            >
                <Select showSearch onSelect={buckethandler} id="bucketName" placeholder="Select Bucket">
                    {bucketsOption?.length > 0 && (
                        <>
                            {bucketsOption.map(name => {
                                return (
                                    <Option key={name} value={name}>{name}</Option>
                                )
                                
                            })}
                        </>
                    )}
                </Select>
            </Form.Item>
            <Form.Item
                label={<>Bucket Folder Name (optional)</>}
                name="bucketFolderName"
                tooltip={{ title: 'Please enter bucket folder name', icon: <QuestionCircleFilled /> }}
                rules={[{
                    validator(_, value) {
                        if(value){
                            value = value.trim();
                            let validRegex = helper.regexValidation(/^[a-z_0-9-]+$/, value);
                            if(validRegex){
                                if(value){
                                    return Promise.resolve();
                                }
                            } else {
                                return Promise.reject(new Error(`Bucket folder names must contain only lowercase letters, numbers, dashes (-), underscores (_). Spaces are not allowed.`))
                            }
                        }else {
                            return Promise.resolve();
                        }
                    }
                }]}
            >
                <Input id="bucketFolderName" onChange={removeSpace} placeholder="Enter bucket folder name" />
            </Form.Item>
            {envars?.storageMechanism === 'minio' && (
                <>
                    <Form.Item
                        label={<>Minio Port</>}
                        name="minioPort"
                        tooltip={{ title: 'Please enter minio port e.g 443', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        if(isNaN(value)){
                                            return Promise.reject(new Error(`Please enter only numeric value.`))
                                        } else if(value.indexOf(".") !== -1) {
                                            return Promise.reject(new Error(`Invalid value. Please remove dot.`))
                                        } else if(value.indexOf("-") !== -1) {
                                            return Promise.reject(new Error(`Invalid value. Please remove hyphen.`))
                                        } else if(value.indexOf("+") !== -1) {
                                            return Promise.reject(new Error(`Invalid value. Please remove plus.`))
                                        } else {
                                            return Promise.resolve()
                                        }
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioPort" onChange={removeSpace} placeholder="Enter minio port" />
                    </Form.Item>
                    <Form.Item
                        label={<>Minio End Point URL</>}
                        name="minioEndPoint"
                        tooltip={{ title: 'Please enter minio end point URL', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioEndPoint" onChange={removeSpace} placeholder="Enter end point" />
                    </Form.Item>
                    <Form.Item
                        label={<>Access Key</>}
                        name="minioAccessKey"
                        tooltip={{ title: 'Please enter minio access key', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioAccessKey" onChange={removeSpace} placeholder="Enter minio access key" />
                    </Form.Item>
                    <Form.Item
                        label={<>Secret Key</>}
                        name="minioSecretKey"
                        tooltip={{ title: 'Please enter minio secret key', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="minioSecretKey" onChange={removeSpace} placeholder="Enter minio secret key" />
                    </Form.Item>
                    <Form.Item
                        label={<>Use SSL</>}
                        name="useSsl"
                        tooltip={{ title: 'Keep it true for https and false for http', icon: <QuestionCircleFilled /> }}
                    >
                        <Switch checkedChildren="True" unCheckedChildren="False" checked={useChecked} onChange={() => setUseChecked(!useChecked)} />
                    </Form.Item>
                </>
            )}
            {envars?.storageMechanism === 'gcp' && (
                <>
                    <Form.Item
                        label={<>Google Application Credentials</>}
                        name="gcpGoogleAppCredentials"
                        tooltip={{ title: 'Please upload JSON file containing google account credentials.', icon: <QuestionCircleFilled /> }}
                    >
                        <Upload id="uploadField" {...uploadProps} fileList={filesList}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                </>
            )}
            {envars?.storageMechanism === 'azure' && (
                <>
                    <Form.Item
                        label={<>Azure Connection String</>}
                        name="azureStorageString"
                        tooltip={{ title: 'Please enter default connection string of Azure.', icon: <QuestionCircleFilled /> }}
                        rules={[{
                            validator(_, value) {
                                if(value){
                                    value = value.trim();
                                    if(value){
                                        return Promise.resolve()
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                } else {
                                    return Promise.reject(new Error(`Required field.`))
                                }
                            }
                        }]}
                    >
                        <Input id="azureStorageString" onChange={removeSpace} placeholder="Enter Azure Connection String" />
                    </Form.Item>
                </>
            )}
            <Form.Item {...tailLayout}>
                {fileError && (
                    <div id="errorDiv" className={fileError.className}>{fileError.message}</div>
                )}
                <Button key="submitUpdate" htmlType="submit" type="primary" loading={loading}>Update</Button>
                <Button key="cancelUpdate" onClick={handleCancel} id="cancelBtn">Cancel</Button>
            </Form.Item>
        </Form>
    )
}

export default UpdateBucket;