
import React from 'react';
import { Typography, Layout, Row, Col, Card  } from "antd";
import './style.scss';
import { useSelector } from 'react-redux';
const { Link } = Typography;
const { Header, Content } = Layout;



const PageNotFound = () =>{
    const { routeLocation } = useSelector(store => store.storeProps);
    let btnLabel = routeLocation && routeLocation.indexOf("/") !== -1 ? routeLocation.replace("/", "") : "";
    btnLabel = btnLabel ? btnLabel : "configuration"
    const bodyEl = document.getElementById('bodyEl');
    if(bodyEl){
      bodyEl.classList.add('dashboardBg');
      bodyEl.classList.remove('loginBg');
    }

    return (
        <Layout>
            <Header className="header">
            <Row>
                <Col span={3}>
                <img src="./fortress-logo-login.png" className="logo-dashboard" alt="FIQ" />
                </Col>
            </Row>
            </Header>
            <Content>
                <Card className="no-page-found-wrapper">
                    <div className="noPageFound">
                        <img src="./404-image.png" alt="404" />
                        <p className="no-page-head">Unfortunately the page you’re looking for doesn’t exist (anymore) or there was an error in the link you followed.</p>
                        <Link  href={routeLocation} className="btnHome">Go to <span style={{textTransform: "capitalize"}}>{btnLabel}</span></Link>
                    </div>
                </Card>
            </Content>
      </Layout>
      
    )
}

export default PageNotFound;