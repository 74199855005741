import React, { useState } from 'react';
import { Row, Col, Button, Form, Input, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { saveScreenSignature } from '../../store/actions';
import ROOT from '../../client';
import "./filter.scss";
import axiosConfig from '../../axiosConfig';

const UpdateSignature = (props) => {
    const dispatch = useDispatch();
    const {indexValue, indexAlias, aliasFlag, filters, observedAtDetail, areaQuery, mappingImageKey} = useSelector(store => store.storeProps);
    const {handleCancel, setUpdateSignatureFlag} = props;
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const onFinish = (formValues) => {
        try{
            if(formValues){
                setIsLoading(true);
 
                const url = `${ROOT}/api/screen-sig`;
                const payload = {
                    index: indexValue,
                    alias: aliasFlag ? indexAlias : '',
                    groups: filters ? filters : '',
                    date: observedAtDetail && observedAtDetail.dateRange ? observedAtDetail.dateRange : '',
                    screenSignature: formValues?.signatureVal ? formValues.signatureVal.trim() : '',
                    area_coordinates: areaQuery && areaQuery.area_coordinates && areaQuery.area_coordinates.length ? areaQuery.area_coordinates : [],
                    startRange: areaQuery && areaQuery.startRange && areaQuery.startRange.length ? areaQuery.startRange : [],
                    endRange: areaQuery && areaQuery.endRange && areaQuery.endRange.length ? areaQuery.endRange : [],
                    mappingKey: mappingImageKey
                };
                axiosConfig.put(url, payload)
                .then(resp => {
                    if(resp && resp.data && resp.data.statusCode === 200){
                        setIsLoading(false);
                        form.resetFields();
                        setUpdateSignatureFlag(false);
                        notification.success({
                            message: 'Screen signature updated successfully.',
                            duration: 3
                        });
                        dispatch(saveScreenSignature(formValues.signatureVal ? formValues.signatureVal : ''));
                    }
                })
                .catch(error => {
                    const errMsg = error?.response?.data?.message ? error.response.data.message.indexOf("Reason: no permissions") !== -1 ? "You don't have permissions to update" : error.response.data.message : "";
                    if(errMsg){
                        notification.error({
                            message: errMsg,
                            duration: 5
                        }) 
                    }
                    setIsLoading(false);
                })
            }
        } catch(error) {
            console.log("function onFinish error: ", error);
        }
    }
    const handleUpdateCancel = () => {
        handleCancel();
        form.resetFields();
    }
    return (
        <div className="add-filters-section">
            <Row className="updateSignature">
                <Col span={24} className="updateCol">
                    <h3>Update Screen Signature</h3>
                </Col>
                <Col span={12} className="updateCol">
                    <Form
                        form={form}
                        name="updateScreenSignatureform"
                        onFinish={onFinish}
                        layout="vertical"
                        className="form-update-signature"
                        >
                        <Form.Item
                            label="Signature"
                            name="signatureVal"
                            rules={[{
                                validator(_, value) {
                                    if(value){
                                        value = value.trim();
                                        if(value){
                                            if(value.indexOf('"') !== -1){
                                                return Promise.reject(new Error(`Value is not valid. Please remove double quote.`));
                                            } else if(value.indexOf("\\") !== -1){
                                                return Promise.reject(new Error(`Value is not valid. Please remove backward slash.`));
                                            } else {
                                                return Promise.resolve()
                                            }
                                        } else {
                                            return Promise.reject(new Error(`Required field.`))
                                        }
                                    } else {
                                        return Promise.reject(new Error(`Required field.`))
                                    }
                                }
                            }]}
                        >
                            <Input id="signatureVal" placeholder="Enter value" />
                        </Form.Item>
                        <Form.Item>
                            <Button disabled={isLoading} onClick={handleUpdateCancel}>Cancel</Button>
                            <Button loading={isLoading} type="primary" htmlType="submit">Update Signature</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default UpdateSignature;
